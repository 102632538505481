import logo from "../../assets/images/logo.png";
import "../../assets/css/style.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import userAvatar from "../../assets/images/avatar.png";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { LuLayoutDashboard } from "react-icons/lu";
import { LiaSignOutAltSolid } from "react-icons/lia";

const Header = () => {
  const [user, setUser] = useState(null);
  const { user: stateUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    Cookies.get("token") && stateUser && setUser(stateUser);
  }, [stateUser]);

  const handleSignOut = (e) => {
    e.preventDefault();
    Cookies.remove("token");
    navigate("/");
    toast.success("User signed out successfully");
  };
  return (
    <div>
      <nav className="navbar">
        <div className="navbar-brand">
          <img src={logo} alt="logo" />
        </div>
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="custom" id="dropdown-basic">
              {`${user?.name ? user?.name.slice(0, 10) + ".." : "US"}`}
              <img
                src={`${
                  user?.name ? userAvatar : "https://flagcdn.com/us.svg"
                }`}
                // src="https://flagcdn.com/us.svg"
                width="30"
                alt="lan"
                style={{ margin: "0px 10px" }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {user && (
                <>
                  <Dropdown.Item
                    style={{ fontSize: "18px" }}
                    onClick={() => navigate("/user-dashboard/home")}
                  >
                    Dashboard
                    <LuLayoutDashboard size="25px" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontSize: "18px" }}
                    onClick={(e) => {
                      handleSignOut(e);
                    }}
                  >
                    Sign out
                    <LiaSignOutAltSolid size="25px" />
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
    </div>
  );
};

export default Header;
