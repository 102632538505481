import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IoMdPeople, IoIosClipboard, IoIosListBox } from "react-icons/io";
import { AiOutlineUser, AiOutlineLogout } from "react-icons/ai";
import { FcSettings } from "react-icons/fc";
import { GiStoneStack, GiStoneCrafting } from "react-icons/gi";
import { BsTruck } from "react-icons/bs";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DashboardMenu() {
  const navigate = useNavigate();
  const [isActiveTab, setIsActiveTab] = useState(false);
  const [show, setShow] = useState(false); // State for showing the logout confirmation modal

  const handleLogout = (e) => {
    e.preventDefault();
    navigate("/dashboard");
    Cookies.remove("adminAuth");
    toast.success("Admin logout successfully");
  };
  // const openLogoutModal = (e) => {
  //   e.preventDefault();
  //   setShowLogoutModal(true);
  // };

  // const closeLogoutModal = () => {
  //   setShowLogoutModal(false);
  // };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <aside
        className={`dashboard-sidebar ${
          isActiveTab ? "responsive-visible" : ""
        }`}
      >
        <img src="/images/lightLogo.svg" alt="Logo" className="brand-logo" />
        <div className="list-group">
          {/* <h4 className="mb-4">SUPER ADMIN</h4> */}
          <NavLink
            to="/dashboard/admin/users"
            className="list-group-item"
            onClick={() => {
              setIsActiveTab(!isActiveTab);
            }}
          >
            <span className="icon">
              <AiOutlineUser className="mr-2" />
            </span>
            <span className="text">Users</span>
          </NavLink>
          <NavLink
            to="/dashboard/admin/reports"
            className="list-group-item"
            onClick={() => {
              setIsActiveTab(!isActiveTab);
            }}
          >
            <span className="icon">
              <IoIosClipboard className="mr-2" />
            </span>
            <span className="text">All reports</span>
          </NavLink>
          <NavLink
            to="/dashboard/admin/pending-reports"
            className="list-group-item"
            onClick={() => {
              setIsActiveTab(!isActiveTab);
            }}
          >
            <span className="icon">
              <IoIosClipboard className="mr-2" />
            </span>
            <span className="text">Pending Reports</span>
          </NavLink>
          <h5 className="bold heading">Catalog</h5>
          <div className="catalogList">
            <NavLink
              to="/dashboard/admin/catalog-projects"
              className="list-group-item"
              onClick={() => {
                setIsActiveTab(!isActiveTab);
              }}
            >
              <span className="icon">
                <IoIosListBox className="mr-2" />
              </span>
              <span className="text">Project Name</span>
            </NavLink>
            <NavLink
              to="/dashboard/admin/catalog-foreman"
              className="list-group-item"
              onClick={() => {
                setIsActiveTab(!isActiveTab);
              }}
            >
              <span className="icon">
                <IoMdPeople className="mr-2" />
              </span>
              <span className="text">Foreman</span>
            </NavLink>
            <NavLink
              to="/dashboard/admin/machine-assets"
              className="list-group-item"
              onClick={() => {
                setIsActiveTab(!isActiveTab);
              }}
            >
              <span className="icon">
                <FcSettings className="mr-2" />
              </span>
              <span className="text">Machine Asset</span>
            </NavLink>
            <NavLink
              to="/dashboard/admin/material-supplier"
              className="list-group-item"
              onClick={() => {
                setIsActiveTab(!isActiveTab);
              }}
            >
              <span className="icon">
                <GiStoneCrafting className="mr-2" />
              </span>
              <span className="text">Material Supplier</span>
            </NavLink>
            <NavLink
              to="/dashboard/admin/material-type"
              className="list-group-item"
              onClick={() => {
                setIsActiveTab(!isActiveTab);
              }}
            >
              <span className="icon">
                <GiStoneStack className="mr-2" />
              </span>
              <span className="text">Material Type</span>
            </NavLink>
            <NavLink
              to="/dashboard/admin/truck"
              className="list-group-item"
              onClick={() => {
                setIsActiveTab(!isActiveTab);
              }}
            >
              <span className="icon">
                <BsTruck className="mr-2" />
              </span>
              <span className="text">Truck</span>
            </NavLink>
          </div>
          {/* logoutButton */}
          <div
            onClick={(e) => handleShow(e)}
            className="list-group-item"
            style={{
              position: "absolute",
              bottom: "7px",
              padding: "5px",
              cursor: "pointer",
              width: "90%",
            }}
          >
            <span className="icon">
              <AiOutlineLogout className="mr-2" />
            </span>
            <span className="text">Logout</span>
          </div>
        </div>
        <span
          className="toggle-btn"
          onClick={() => {
            setIsActiveTab(!isActiveTab);
          }}
        >
          <img
            src="/images/ic_arrow_right.svg"
            alt="Toggle Sidebar"
            className="arrow"
          />
          <img
            src="/images/ic_menu.svg"
            alt="Toggle Sidebar"
            className="menu"
          />
        </span>
      </aside>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          style={{ top: "30vh" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to Logout?</Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <Button variant="secondary" onClick={() => handleClose(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleLogout}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default DashboardMenu;
