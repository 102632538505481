import React from "react";
import "../assets/css/style.css";
import Layout from "../components/layout/Layout";
import editIcon from "../assets/images/edit_icon.svg";
import plusImage from "../assets/images/feather-plus-square.svg";
import deleteIcon from "../assets/images/delete.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateAllcards } from "../redux/employeeTimecard/timeCardSlice";

const Review = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    employee,
    employeeHours,
    machineHours,
    materialHours,
    truckingHours,
  } = useSelector((state) => state.employeeTimecardData);

  let totalHours = 0;
  let totalMinutes = 0;
  employeeHours?.forEach((hour) => {
    totalHours += hour.totalTime.hours;
    totalMinutes += hour.totalTime.minutes;
  });
  if (totalMinutes >= 60) {
    const additionalHours = Math.floor(totalMinutes / 60);
    totalHours += additionalHours;
    totalMinutes %= 60;
  }

  const handleRemove = (e) => {
    e.preventDefault();
    dispatch(
      updateAllcards({
        employee: "",
        employeeHours: [],
        machineHours: [],
        materialHours: [],
        truckingHours: [],
      })
    );
  };
  return (
    <Layout>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 align-self-center text-center">
              <h2 className="heading">Timecards</h2>
              <p className="mb-3">
                {/* There are no employee records for this job */}
              </p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  navigate("/employeetimecard");
                }}
              >
                {employeeHours.length > 0 ||
                truckingHours.length > 0 ||
                machineHours.length > 0 ||
                materialHours.length > 0 ? (
                  <span>
                    <img src={editIcon} alt="+" style={{ width: "18px" }} />
                    Edit
                  </span>
                ) : (
                  <span>
                    <img src={plusImage} alt="+" /> Employee
                  </span>
                )}
              </button>
            </div>
            <div className="col-xl-5 col-lg-6 ml-auto">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <div
                      className="form-row "
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="col-lg-6">
                        <h4>{employee ? employee : ""}</h4>
                      </div>
                      <div>
                        {employeeHours.length > 0 ||
                        truckingHours.length > 0 ||
                        machineHours.length > 0 ||
                        materialHours.length > 0 ? (
                          <div>
                            {/* <button
                            style={{
                              border: "none",
                              alignItems: "center",
                              textAlignL: "center",
                            }}
                            onClick={(e) => {
                              navigate("/employeetimecard");
                            }}
                          >
                            <img
                              src={editIcon}
                              alt="edit"
                              style={{ width: "22px" }}
                            />
                            Edit
                          </button>
                          {"  |  "} */}
                            <button
                              type="button"
                              style={{
                                border: "none",
                                marginBottom: "3px",
                                textAlign: "end",
                              }}
                              onClick={(e) => handleRemove(e)}
                            >
                              <img
                                src={deleteIcon}
                                width={"22px"}
                                alt="deleteicon"
                              />
                              delete
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <ul className="list-group">
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Time Logged
                        <span className="">
                          {`${totalHours} hrs ${totalMinutes} mins`}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Machines Used
                        <span className="">
                          {machineHours ? machineHours.length : "0"}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Materials Used
                        <span className="">
                          {materialHours ? materialHours.length : "0"}
                        </span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        Trucking Used
                        <span className="">
                          {truckingHours ? truckingHours.length : "0"}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <form>
                    {/* <div className="form-group">
                      <label>Notes</label>
                      <textarea
                        name=""
                        id=""
                        rows="5"
                        className="form-control"
                        value={notes}
                        onChange={handleChangeNotes}
                      ></textarea>
                    </div> */}

                    <button
                      className="btn btn-primary w-100"
                      onClick={() => {
                        navigate("/generalreview");
                      }}
                    >
                      Review
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Review;
