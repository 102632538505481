import React, { useEffect, useState } from "react";
import {
  getTrucksDropdown,
  updateTruckDropdown,
  deleteTruckDropdown,
  postTruckDropdown,
} from "../../../apiServices/AdminDashboardApiServices";
import { toast } from "react-hot-toast";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";

function Truck() {
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editedTruck, setEditedTruck] = useState("");
  const [newTruck, setNewTruck] = useState("");
  const [inputError, setInputError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        setLoading(true);
        const result = await getTrucksDropdown();
        setData(result.data);
        setLoading(false);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message);

        setLoading(false);
      }
    };

    fetchDataAsync();
  }, []);

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedTruck(data[index].truck);
  };

  const handleUpdateClick = async () => {
    // Clone the original data
    const updatedData = [...data];

    updatedData[editingIndex].truck = editedTruck;

    try {
      setLoading(true);
      const response = await updateTruckDropdown(
        updatedData[editingIndex]._id,
        editedTruck
      );
      if (response.success === true) {
        // Update the dvalueata state with the updated data
        setData(updatedData);
        setLoading(false);
        setEditingIndex(-1);
        setEditedTruck("");
        toast.success("Truck updated successfully");
      } else {
        setLoading(false);
        toast.error("Some error occurred");
      }
    } catch (error) {
      setLoading(false);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const handleDeleteClick = async (index) => {
    try {
      setLoading(true);
      const truckIdToDelete = data[index]._id;

      const response = await deleteTruckDropdown(truckIdToDelete);

      if (response.success) {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
        setLoading(false);
        toast.success("Truck deleted successfully");
      } else {
        setLoading(false);
        toast.error("Some error occurred");
      }
    } catch (error) {
      setLoading(false);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const handleChange = (event) => {
    setNewTruck(event.target.value);
    setInputError("");
  };

  const addNewTruck = async () => {
    if (newTruck.trim() === "") {
      setInputError("Please fill in the truck name.");
      return;
    }

    try {
      setLoading(true);
      const response = await postTruckDropdown({
        truckListValue: newTruck,
      });
      if (response.success === true) {
        const newData = {
          _id: response.data._id,
          truck: response.data.truck,
        };
        setData([newData, ...data]);
        setNewTruck("");
        setInputError("");
        setLoading(false);
        toast.success("Truck added successfully");
      } else {
        setLoading(false);
        toast.error("Some error occurred");
      }
    } catch (error) {
      setLoading(false);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-75">
        <div className="spinner-border text-primary" role="status"></div>
      </div>
    );
  }

  return (
    <div className="card w-100 p-3 m-3">
      <div
        style={{ display: "flex", gap: "5px", justifyContent: "space-between" }}
      >
        <input
          type="text"
          onChange={handleChange}
          value={newTruck}
          className={`form-control ${inputError ? "is-invalid" : ""}`}
          placeholder="New Truck Name"
        />
        <div className="addNewItemButton">
          <button onClick={addNewTruck}>
            <FaPlus className="mr-1" /> Add
          </button>
        </div>
      </div>
      {inputError && <span className="text-danger">{inputError}</span>}
      <div className="row">
        <div className="col-sm-12 overflow-auto">
          <table className="table mt-4 ">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Truck Name</th>
                <th scope="col" style={{ textAlign: "center" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.length === 0 ? (
                <tr>
                  <td colSpan="3" className="text-center">
                    No data found
                  </td>
                </tr>
              ) : (
                data.map((truckDropdownlist, index) => (
                  <tr className="buttonResponsive" key={truckDropdownlist._id}>
                    <th scope="row ">{index + 1}</th>
                    <td>
                      {editingIndex === index ? (
                        <input
                          className="form-control"
                          type="text"
                          value={editedTruck}
                          onChange={(evnt) => setEditedTruck(evnt.target.value)}
                        />
                      ) : (
                        truckDropdownlist.truck
                      )}
                    </td>
                    <td
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {editingIndex === index ? (
                        <button
                          className="btn btn-success btn-sm"
                          onClick={handleUpdateClick}
                        >
                          <FaEdit /> Update
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleEditClick(index)}
                        >
                          <FaEdit /> Edit
                        </button>
                      )}
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDeleteClick(index)}
                      >
                        <FaTrash /> Delete
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Truck;
