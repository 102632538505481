import React, { useEffect, useState } from "react";
import plusImage from "../../assets/images/feather-plus-square.svg";
import "../../assets/css/style.css";
import { useSelector, useDispatch } from "react-redux";
import { updateTruckingHours } from "../../redux/employeeTimecard/timeCardSlice";
import { getTrucking } from "../../apiServices/clientApiServices";
import { toast } from "react-hot-toast";

const TruckingHoursCard = ({ generalreviewStatus, employeeTimeCardStatus }) => {
  const dispatch = useDispatch();
  const { truckingHours } = useSelector((state) => state.employeeTimecardData);
  const [employeeName, setEmployeeName] = useState("");
  const [trucking, setTrucking] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    setEmployeeName(user?.name);
  }, [user]);
  useEffect(() => {
    const fetchAssetData = async () => {
      try {
        setLoading(true);
        const response = await getTrucking();
        if (response.data.success === true) {
          setTrucking(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setTrucking([]);
        toast.error(message);
      }
    };

    fetchAssetData();
  }, []);
  const handleClick = (e) => {
    e.preventDefault();
    const newCard = {
      id: truckingHours.length + 1,
      truck: "",
      startMileage: "",
      endMileage: "",
      totalMileage: 0,
      startTime: "",
      endTime: "",
      totalTime: { hours: "0", minutes: "0" },
      employeeName: employeeName,
      error: false,
    };
    const updatedCards = [...truckingHours, newCard];
    dispatch(updateTruckingHours({ cards: updatedCards }));
  };

  const removeCard = (e, cardId) => {
    e.preventDefault();
    const updatedCards = truckingHours
      .filter((card) => card.id !== cardId)
      .map((card, index) => {
        return {
          ...card,
          id: index + 1,
        };
      });
    dispatch(updateTruckingHours({ cards: updatedCards }));
  };

  const handleInputValues = (e, index, field) => {
    const { value } = e.target;

    const updatedCards = truckingHours.map((card, cardIndex) => {
      if (cardIndex === index) {
        const updatedCard = {
          ...card,
          [field]: value,
        };

        // Check for errors
        let error = false;

        if (field === "endMileage") {
          error = parseInt(updatedCard.startMileage) >= parseInt(value);
        } else if (
          field === "startTime" &&
          updatedCard.endTime &&
          parseInt(value.replace(":", "")) >=
            parseInt(updatedCard.endTime.replace(":", ""))
        ) {
          error = true;
        } else if (
          field === "endTime" &&
          updatedCard.startTime &&
          parseInt(value.replace(":", "")) <=
            parseInt(updatedCard.startTime.replace(":", ""))
        ) {
          error = true;
        }

        updatedCard.error = error;
        return updatedCard;
      }
      return card;
    });

    const startMileage = Number(updatedCards[index].startMileage);
    const endMileage = Number(updatedCards[index].endMileage);
    const startTime = updatedCards[index].startTime;
    const endTime = updatedCards[index].endTime;

    // Calculate total mileage
    updatedCards[index].totalMileage =
      endMileage >= startMileage ? endMileage - startMileage : 0;

    // Calculate total time and handle errors for individual cards
    if (startTime && endTime) {
      const startDateTime = new Date(`01/01/2021 ${startTime}`);
      const endDateTime = new Date(`01/01/2021 ${endTime}`);
      const totalTimeInMinutes = (endDateTime - startDateTime) / 1000 / 60; // Calculate difference in minutes

      if (totalTimeInMinutes < 0) {
        updatedCards[index].totalTime = { hours: "", minutes: "" };
        updatedCards[index].error = true; // Set error for the "End Time" field
      } else {
        const totalHours = Math.floor(totalTimeInMinutes / 60);
        const totalMinutes = totalTimeInMinutes % 60;

        updatedCards[index].totalTime = {
          hours: totalHours,
          minutes: totalMinutes,
        };
        updatedCards[index].error = false; // Reset the error for the "End Time" field
      }
    } else {
      updatedCards[index].totalTime = { hours: "", minutes: "" };
      updatedCards[index].error = false; // Reset the error for the "End Time" field
    }

    dispatch(updateTruckingHours({ cards: updatedCards })); // Dispatch the action to update Redux store
    return updatedCards;
  };

  return (
    <div>
      <div style={{ display: `${employeeTimeCardStatus ? "inline" : "none"}` }}>
        {truckingHours.map((card, index) => (
          <div key={card.id}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Truck</label>
                    <select
                      id="inputTruck"
                      className="form-control"
                      value={card.truck}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "truck");
                      }}
                    >
                      <option value="" disabled>
                        {loading ? "Loading..." : "Choose..."}
                      </option>
                      {trucking?.length > 0 ? (
                        trucking?.map((option) => (
                          <option key={option._id} value={option.truck}>
                            {option.truck}
                          </option>
                        ))
                      ) : (
                        <option disabled>No data found</option>
                      )}
                    </select>
                    {card.truck === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Start Mileage</label>
                  <input
                    type="number"
                    className="form-control"
                    value={card.startMileage}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "startMileage");
                    }}
                  />
                  {card.startMileage === "" && (
                    <span className="required-fields">Required</span>
                  )}
                </div>
                <div className="col">
                  <label> End Mileage</label>
                  <input
                    type="number"
                    className="form-control"
                    value={card.endMileage}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "endMileage");
                    }}
                  />
                  {card.endMileage === "" && (
                    <span className="required-fields">Required</span>
                  )}
                  {parseInt(card.startMileage) > parseInt(card.endMileage) && (
                    <p style={{ color: "red" }}>
                      End must be greater than Start
                    </p>
                  )}
                </div>
                <div className="col">
                  <label> Total Mileage</label>
                  <input
                    type="number"
                    className="form-control"
                    value={card.totalMileage}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Start Time</label>
                  <input
                    type="time"
                    className="form-control"
                    value={card.startTime}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "startTime");
                    }}
                  />
                  {card.startTime === "" && (
                    <span className="required-fields">Required</span>
                  )}
                </div>
                <div className="col">
                  <label> End Time</label>
                  <input
                    type="time"
                    className="form-control"
                    value={card.endTime}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "endTime");
                    }}
                  />
                  {card.endTime === "" && card.startTime === "" && (
                    <span className="required-fields">Required</span>
                  )}
                  {card.endTime < card.startTime && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      End must be greater than Start
                    </p>
                  )}
                </div>
                <div className="col">
                  <label> Total Time</label>
                  <input
                    className="form-control"
                    value={`${card.totalTime.hours}h ${card.totalTime.minutes}m`}
                    disabled
                  />
                </div>
              </div>
            </div>
            {/* Render card content */}
            <div className="removeBtn mt-2">
              <button onClick={(e) => removeCard(e, card.id)}>Remove</button>
            </div>
          </div>
        ))}

        {/* Button to add a new card */}
        <button
          className="btn btn-default w-100"
          id="machinebtn"
          onClick={handleClick}
        >
          <img src={plusImage} alt="btnImg" />
          Trucking Hours
        </button>
        <div className="row mt-5">
          <div className="col">
            <div className="form-group">
              <label>Description</label>
              <textarea
                type="text"
                id="inputAsset"
                className="form-control"
                rows="3"
                cols="20"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      {/* below card is for generalReview component */}
      <div style={{ display: `${generalreviewStatus ? "inline" : "none"}` }}>
        <h4 className="bold">Trucking Hours</h4>
        <div className="table-wrapper">
          <table className="table trucking-table">
            <colgroup>
              <col width="15%" />
              <col width="18%" />
              <col width="10%" />
              <col width="12%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="15%" />
              <col width="10%" />
              {/* <col width="28%" /> */}
            </colgroup>
            <thead>
              <tr>
                <th>Trucking</th>
                <th>Driver</th>
                <th>Start Mileage</th>
                <th>End Mileage</th>
                <th>Total Mileage</th>
                <th> Start Time </th>
                <th>End Time</th>
                <th>Total Time</th>
                <th> Edit </th>
                <th className="print--hidden"></th>
              </tr>
            </thead>
            <tbody>
              {truckingHours?.map((data, index) => (
                <tr key={data?.id}>
                  <td>
                    <select
                      id="inputTruck"
                      className="form-control"
                      value={data.truck}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "truck");
                      }}
                    >
                      <option value="" disabled>
                        {loading ? "Loading..." : "Choose..."}
                      </option>
                      {trucking?.length > 0 ? (
                        trucking?.map((option) => (
                          <option key={option._id} value={option.truck}>
                            {option.truck}
                          </option>
                        ))
                      ) : (
                        <option disabled>No data found</option>
                      )}
                    </select>
                    {data.truck === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      className={`js-example-placeholder-single js-states form-control`}
                      value={data?.employeeName}
                      required
                      onChange={(e) =>
                        handleInputValues(e, index, "employeeName")
                      }
                      disabled
                    />
                  </td>

                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={data.startMileage}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "startMileage");
                      }}
                    />
                    {data.startMileage === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control "
                      value={data.endMileage}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "endMileage");
                      }}
                    />
                    {data.endMileage === "" && (
                      <span className="required-fields">Required</span>
                    )}
                    {parseInt(data.startMileage) >
                      parseInt(data.endMileage) && (
                      <p style={{ color: "red", fontSize: "10px" }}>
                        End must be greater than Start
                      </p>
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={data.totalMileage}
                      required
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      className="form-control"
                      value={data.startTime}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "startTime");
                      }}
                    />
                    {data.startTime === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="time"
                      className="form-control"
                      value={data.endTime}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "endTime");
                      }}
                    />
                    {data.endTime < data.startTime && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        End must be greater than Start
                      </p>
                    )}

                    {data.endTime === "" && data.startTime === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>

                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={`${data.totalTime.hours}h ${data.totalTime.minutes}m`}
                      disabled
                    />
                  </td>
                  <td>
                    <td className="print--hidden text-center">
                      <button
                        className="btn"
                        onClick={(e) => removeCard(e, data.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </td>
                </tr>
              ))}

              <tr className="addtr">
                <td colSpan="10">
                  <button
                    className="btn btn-default w-100"
                    id="addrow"
                    onClick={handleClick}
                  >
                    <img src={plusImage} alt="+" />
                    Trucking Time
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TruckingHoursCard;
