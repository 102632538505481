import React from "react";
import Layout from "../components/layout/Layout";
import plusImage from "../assets/images/feather-plus-square.svg";
import "../assets/css/style.css";
const AddEmployee = () => {
  return (
    <Layout>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h2 className="heading">Add Employee</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <form action="">
                <h4 className="bold">Select State</h4>
                <div className="form-lg-group">
                  <label>Employee</label>
                  <select className="js-example-placeholder-single js-states form-control">
                    <option></option>
                    <option value="2">Beck, Larry (2)</option>
                    <option value="392">Billman, Bill (392)</option>
                  </select>
                </div>

                <h4 className="bold">Employee Hours</h4>
                <div className="emp-append"></div>
                <div className="form-lg-group">
                  <button className="btn btn-default w-100" id="empbtn">
                    <img src={plusImage} alt="+" /> Add Time
                  </button>
                </div>

                <h4 className="bold">Machine Hours</h4>
                <div className="machine-append"></div>
                <div className="form-lg-group">
                  <button className="btn btn-default w-100" id="machinebtn">
                    <img src={plusImage} alt="+" />
                    Add Time
                  </button>
                </div>

                <div className="row mt-4 gap-2">
                  <div className="col-lg-5">
                    <button className="btn btn-secondary w-100">Cancel</button>
                  </div>
                  <div className="col-lg-5 ml-auto">
                    <button className="btn btn-primary w-100">Next</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AddEmployee;
