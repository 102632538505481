import React from "react";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

const AdminProtectedRoute = ({ children }) => {
  // const navigate = useNavigate();

  const adminToken = Cookies.get("adminAuth");
  if (!adminToken || adminToken === undefined || adminToken === null) {
    return <Navigate to="/dashboard" />;
  } else {
    return <>{children}</>;
  }
};

export default AdminProtectedRoute;

//  useEffect(() => {
//     const authToken = localStorage.getItem("authToken");
//     const refreshToken = localStorage.getItem("refreshToken");
//     if (
//       !authToken ||
//       authToken === undefined ||
//       authToken === null ||
//       !refreshToken ||
//       refreshToken === undefined ||
//       refreshToken === null
//     ) {
//       router.push("/authentication/login");
//     } else {
//       (async () => {
//         const response = await verifyTokenApi({
//           authToken,
//           refreshToken,
//         })
//           .then(() => {
//             setAuthToken(authToken!);
//             setLoading(false);
//           })
//           .catch((error) => {
//             console.log(error);
//             localStorage.removeItem("authToken");
//             localStorage.removeItem("refreshToken");
//           });

//         console.log(response);
//       })();
//     }
//   }, []);
