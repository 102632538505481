import React, { useEffect, useState } from "react";

const DownloadCard = ({ Record }) => {
  const [record, setRecord] = useState(Record);

  useEffect(() => {
    setRecord(Record);
  }, [Record]);
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  return (
    <div className="container-fluid">
      <div id="print-form">
        <div className="card w-100 ">
          <div className="card-body p-4  w-100 " style={{ minHeight: "90vh" }}>
            <div className="row justify-content-center mb-3">
              <div className="col-lg-3">
                <div className="form-group">
                  <label>Project Name</label>
                  <input
                    value={record?.projectName ? record.projectName : ""}
                    className="form-control"
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Foreman</label>
                  <input
                    value={record?.foreman ? record.foreman : ""}
                    className="form-control"
                    disabled
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label>Date: </label>
                  <input
                    value={formatDate(record?.date)}
                    className="form-control"
                    disabled
                  />
                </div>
              </div>
            </div>

            {record?.employeeHours.length > 0 && (
              <div className="Print-tableContainer">
                <h4 className="bold  p-2">Employee Hours</h4>
                <table className="table table-hover ">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Total</th>
                      <th>O/L</th>
                    </tr>
                  </thead>
                  <tbody>
                    {record?.employeeHours?.map((data) => (
                      <tr key={data?.id}>
                        <td>{data?.employeeName}</td>
                        <td>{data?.start}</td>
                        <td>{data?.end}</td>
                        <td>
                          {`${data?.totalTime?.hours}h : ${data?.totalTime?.minutes} m`}
                        </td>

                        <td>{data?.ol}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {record?.machineHours.length > 0 && (
              <div className="Print-tableContainer">
                <h4 className="bold">Machine Hours</h4>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Asset ID </th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Total</th>
                      <th>Description</th>
                      <th>Name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {record?.machineHours?.map((data) => (
                      <tr key={data.id}>
                        <td>{data?.asset}</td>
                        <td>{data?.start}</td>
                        <td>{data?.end}</td>
                        <td>{data?.total}</td>
                        <td>{data?.description}</td>
                        <td>{data?.employeeName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* material Hours */}
            {record?.materialHours.length > 0 && (
              <div className="Print-tableContainer">
                <h4 className="bold">Materials</h4>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Material/Supplier</th>
                      <th>#Loads</th>
                      <th>Quantity</th>
                      <th>Material Type</th>
                      <th>Slip #</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {record?.materialHours?.map((data) => (
                      <tr key={data?.id}>
                        <td>{data?.materialtype}</td>
                        <td>{data.load}</td>
                        <td>{data.quantity}</td>
                        <td>{data.materialtype}</td>
                        <td>{data.slip}</td>
                        <td>{data.employeeName}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* trucking Hours */}
            {record?.truckingHours.length > 0 && (
              <div className="Print-tableContainer">
                <h4 className="bold">Trucking Hours</h4>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Trucking</th>
                      <th>Driver</th>
                      <th>Start Mileage</th>
                      <th>End Mileage</th>
                      <th>Total Mileage</th>
                      <th> Start Time </th>
                      <th>End Time</th>
                      <th>Total Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {record?.truckingHours?.map((data) => (
                      <tr key={data.id}>
                        <td>{data.truck}</td>
                        <td>{data?.employeeName}</td>

                        <td>{data.startMileage}</td>
                        <td>{data.endMileage}</td>
                        <td>{data.totalMileage}</td>
                        <td>{data.startTime}</td>
                        <td>{data.endTime}</td>
                        <td>
                          {`${data.totalTime.hours}h:${data.totalTime.minutes}m`}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadCard;
