import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser } from "../../redux/user/userSlice";
import { verifyUserToken } from "../../apiServices/clientApiServices";
import { toast } from "react-hot-toast";

const useAuth = () => {
  const tokken = Cookies.get("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const verifyToken = async () => {
    try {
      const response = await verifyUserToken();
      if (response.data.success === true) {
        await Cookies.set("token", response.data.token);
        dispatch(addUser(response.data.user));
        return true;
      } else {
        Cookies.remove("token");
        navigate("/");
        return false;
      }
    } catch (error) {
      Cookies.remove("token");
      navigate("/");
      toast.error("Unauthorised Token");
      return false;
    }
  };

  useEffect(() => {
    verifyToken();
  }, [dispatch, navigate]);

  return verifyToken();
};

const ProtectedRoutes = () => {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
