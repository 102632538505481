import React, { useEffect, useState } from "react";
import {
  getProjectNames,
  updateProjectName,
  deleteProjectName,
  postProjectNames,
} from "../../../apiServices/AdminDashboardApiServices";
import { toast } from "react-hot-toast";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";

function ProjectName() {
  const [editingIndex, setEditingIndex] = useState(-1); // Default value for index
  const [editedProject, setEditedProject] = useState("");
  const [newProject, setNewProject] = useState(""); // State for the new project name
  const [inputError, setInputError] = useState(""); // State for input error

  const [data, setData] = useState(null); // Set initial data state to null
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        setLoading(true);
        const result = await getProjectNames();
        setData(result);
        setLoading(false);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message);

        setLoading(false);
      }
    };

    fetchDataAsync();
  }, []);

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedProject(data.data[index].projectName);
  };

  const handleUpdateClick = async () => {
    // Update the project name for the specific index
    const updatedData = [...data.data];
    updatedData[editingIndex].projectName = editedProject;

    try {
      setLoading(true);
      const response = await updateProjectName(
        updatedData[editingIndex]._id,
        editedProject
      );
      if (response.success) {
        setData({ ...data, data: updatedData });
        setLoading(false);
        setEditingIndex(-1);
        setEditedProject("");
        toast.success("Project updated successfully");
      } else {
        setLoading(false);
        toast.error("some error occured");
      }
    } catch (error) {
      setLoading(false);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const handleDeleteClick = async (index) => {
    try {
      setLoading(true);
      const projectIdToDelete = data.data[index]._id;

      // Call the API to delete the project
      const response = await deleteProjectName(projectIdToDelete);

      if (response.success) {
        // Remove the deleted project from the data
        const updatedData = data.data.filter((project, i) => i !== index);
        setData({ ...data, data: updatedData });
        setLoading(false);
        toast.success("Project deleted successfully");
      } else {
        setLoading(false);
        // Handle the error from API response if needed
      }
    } catch (error) {
      setLoading(false);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const handleChange = (event) => {
    setNewProject(event.target.value);
    setInputError("");
  };

  const addNewProject = async () => {
    // Check if the new project name is not empty
    if (newProject.trim() === "") {
      setInputError("Please fill in the project name.");
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const response = await postProjectNames({ projectName: newProject });

      if (response.success) {
        // Add the new project to the beginning of the data array
        const newData = { _id: response.data._id, projectName: newProject };
        setData({ ...data, data: [newData, ...data.data] });
        setNewProject("");
        setInputError("");
        setLoading(false);
        toast.success("Project added successfully");
      } else {
        setLoading(false);
        toast.error("some error occured");
      }
    } catch (error) {
      setLoading(false);
      setError("network error");
      toast.error("network error");
    }
  };
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-75">
        <div className="spinner-border text-primary" role="status"></div>
      </div>
    );
  }
  return (
    <div className="card w-100 p-3 m-3 ">
      <div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "space-between",
          }}
        >
          <input
            type="text"
            onChange={handleChange}
            value={newProject}
            className={`form-control ${inputError ? "is-invalid" : ""}`}
            placeholder="New Project Name"
          />
          <div className="addNewItemButton">
            <button onClick={addNewProject} className="addNewItemButton">
              <FaPlus className="mr-1" /> Add
            </button>
          </div>
        </div>
        {inputError && <span className="text-danger">{inputError}</span>}

        {/* Table */}
        <div className="row">
          <div className="col-sm-12 overflow-auto">
            <table className="table mt-4">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">Project Name</th>
                  <th scope="col" style={{ textAlign: "center" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.length === 0 ? (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No data found
                    </td>
                  </tr>
                ) : (
                  data?.data?.map((projectList, index) => (
                    <tr key={index} className="buttonResponsive">
                      <th scope="row ">{index + 1}</th>
                      <td>
                        {editingIndex === index ? (
                          <input
                            className="form-control"
                            type="text"
                            value={editedProject}
                            onChange={(evnt) =>
                              setEditedProject(evnt.target.value)
                            }
                          />
                        ) : (
                          projectList.projectName
                        )}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {editingIndex === index ? (
                          <button
                            className="btn btn-success btn-sm"
                            onClick={handleUpdateClick}
                          >
                            <FaEdit /> Update
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleEditClick(index)}
                          >
                            <FaEdit /> Edit
                          </button>
                        )}
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDeleteClick(index)}
                        >
                          <FaTrash /> Delete
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectName;
