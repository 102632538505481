import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import generalInfoReducer from "./generalInformation/generalinformationSlice";
import employeeTimecardReducer from "./employeeTimecard/timeCardSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    generalInfo: generalInfoReducer,
    employeeTimecardData: employeeTimecardReducer,
  },
});

export default store;
