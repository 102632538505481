import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_API;
// const token = Cookies.get("token");
const apiClient = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json", // You can set other headers here as needed
  },
});
apiClient.interceptors.request.use(
  async (config) => {
    try {
      const token = await Cookies.get("token");
      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      console.error("Error retrieving token:", error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const verifyUserToken = async () => {
  try {
    const response = await apiClient.get("/clientVeryfyToken");
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProjectNames = async () => {
  return await apiClient.get("/projectname-client");
};
export const getForeman = async () => {
  return await apiClient.get("/foreman-client");
};
export const getMachineHoursAsset = async () => {
  return await apiClient.get("/machine-hours-asset-client");
};
export const getMaterialSupplier = async () => {
  return await apiClient.get("/material-hours-supplier-client");
};
export const getMaterialType = async () => {
  return await apiClient.get("/material-hours-type-client");
};
export const getTrucking = async () => {
  return await apiClient.get("/trucking-hours-truck-client");
};

export const postUserRecords = async ({ data }) => {
  const {
    userId,
    projectName,
    foreman,
    date,
    notes,
    employee,
    employeeHours,
    machineHours,
    materialHours,
    truckingHours,
  } = data;
  try {
    const response = await apiClient.post("/post-user-records", {
      userId,
      projectName,
      foreman,
      date,
      notes,
      employee,
      employeeHours,
      machineHours,
      materialHours,
      truckingHours,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getAllReports = async (status) => {
  try {
    const response = await apiClient.get("/user-dashboard-getallreports", {
      params: { status },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getUserDetails = async (id) => {
  try {
    const response = await apiClient.get(`/get-user-details/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
export const updateUserDetails = async (id, editedData) => {
  try {
    const { name, address, phone } = editedData;
    const response = await apiClient.put(`/update-user-details/${id}`, {
      name,
      address,
      phone,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
