import React, { useEffect, useState, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import DownloadCard from "./DownloadCard";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { getAllReports } from "../../apiServices/clientApiServices";
import ReactToPrint from "react-to-print";

const AllReportes = () => {
  const [data, setData] = useState([]); // Set initial data state to null
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [getStatus, setStatus] = useState(null);
  const [record, setRecord] = useState();
  const [show, setShow] = useState(false);
  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        setLoading(true);
        setError(null);
        const result = await getAllReports(getStatus);
        setData(result.data?.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Error fetching data.");
        setLoading(false);
      }
    };

    fetchDataAsync();
  }, [getStatus]);
  const componentPdf = useRef();

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handlePrintForm = (records) => {
    setRecord(records);
    setShow(true);
  };

  function Loading() {
    if (loading) {
      return (
        <div className="d-flex justify-content-center p-5">
          <div className="spinner-border" role="status"></div>
        </div>
      );
    }
  }
  function EmptyData() {
    if (!error || !data) {
      return (
        <div>
          <div className="card w-100 p-3 m-3">
            <h4 className="bold  p-2">No data found</h4>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h3>Reports</h3>
        </div>
        <Tab.Container id="left-tabs" defaultActiveKey="first">
          <Col sm={6}>
            <Nav variant="pills" className="flex-row ">
              <Nav.Item onClick={() => setStatus(null)}>
                <Nav.Link eventKey="first">All Reports</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setStatus("pending")}>
                <Nav.Link eventKey="second">Pending</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setStatus("approved")}>
                <Nav.Link eventKey="third">Approved</Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setStatus("rejected")}>
                <Nav.Link eventKey="fourth">Rejected</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Tab.Container>
      </div>
      <hr></hr>

      <table
        className="table"
        style={{ borderCollapse: "separate", borderSpacing: "0px 10px" }}
      >
        <thead className="reportsHead">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Date</th>
            <th scope="col">Download</th>
            <th scope="col">Status</th>
          </tr>
        </thead>

        {!loading &&
          data?.map((records) => (
            <tbody
              style={{
                boxShadow: "0px 6px 22px #00000014",
              }}
              className="reportsTableBody"
              key={records._id}
            >
              <tr>
                <td>
                  <span>{records.employee}</span>
                </td>
                <td>
                  <span>{formatDate(records.date)}</span>
                </td>
                <td>
                  {records.status === "approved" ? (
                    <img
                      src="/images/download.svg"
                      alt="download"
                      style={{ cursor: "pointer", textAlign: "center" }}
                      onClick={() => handlePrintForm(records)}
                    />
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  <span
                    style={{
                      border: "1px solid black",
                      padding: "6px 30px ",
                      borderRadius: "5px",
                      background:
                        records.status === "approved"
                          ? "#6CF361"
                          : records.status === "rejected"
                          ? "#FF4747"
                          : "white",
                      color:
                        records.status === "rejected" ? "#FFFFFF" : "black",
                    }}
                  >
                    {records?.status.toUpperCase()}
                  </span>
                </td>
              </tr>
            </tbody>
          ))}
      </table>
      {loading && <Loading />}
      {!loading && data?.length === 0 && <EmptyData />}
      {!loading && error && (
        <div className="card w-100 p-3 m-3">
          <h4 className="bold  p-2">Network error</h4>
        </div>
      )}
      <Modal
        show={show}
        size="xl"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="fullscreen"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Your Approval Report
          </Modal.Title>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "20px",
              marginRight: "14px",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <img
                  src="/images/download.svg"
                  alt="download"
                  style={{ cursor: "pointer" }}
                />
              )}
              content={() => componentPdf.current}
            />

            <AiOutlineClose
              size={"25"}
              cursor={"pointer"}
              onClick={() => setShow(false)}
            />
          </div>
        </Modal.Header>

        <Modal.Body className="m-0 p-2 overflow-y-scroll ">
          <div ref={componentPdf} style={{ padding: "10px", height: "100vh" }}>
            <DownloadCard Record={record} id="print-form" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllReportes;
