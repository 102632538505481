import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { fetchUsers } from "../../apiServices/AdminDashboardApiServices";
function Users() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const result = await fetchUsers(); // Replace with the actual endpoint
        setData(result);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data."); // You can customize the error message here
        setLoading(false);
      }
    };

    fetchDataAsync();
  }, []);

  if (loading) {
    return (
      <div className="card w-100 p-3 m-3">
        <h4 className="bold  p-2">Loading...</h4>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div className="card w-100 p-3 m-3">
      <div className="table-responsive">
        <h4 className="bold  p-2">Users</h4>
        <Table style={{ border: "none" }}>
          <thead>
            <tr>
              <th
                style={{ border: "none", borderBottom: "1px solid #8080806e" }}
              >
                Avatar
              </th>
              <th
                style={{ border: "none", borderBottom: "1px solid #8080806e" }}
              >
                Name
              </th>
              <th
                style={{ border: "none", borderBottom: "1px solid #8080806e" }}
              >
                Email
              </th>
              <th
                style={{ border: "none", borderBottom: "1px solid #8080806e" }}
              >
                Number
              </th>
              <th
                style={{ border: "none", borderBottom: "1px solid #8080806e" }}
              >
                Address
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.users?.map((user, index) => (
              <tr key={index}>
                <td>
                  <img
                    src="/images/userAvatar.png"
                    alt={`Avatar of User ${index + 1}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.address}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default Users;

// import React from "react";
// import { Table } from "react-bootstrap";

// const dummyUsers = [
//   {
//     avatarUrl: "https://via.placeholder.com/50", // Example avatar URL
//     email: "user1@example.com",
//     name: "example",
//     phone: "123-456-7890",
//     address: "123 Main Street, City, Country",
//   },
//   {
//     avatarUrl: "https://via.placeholder.com/50", // Example avatar URL
//     email: "user2@example.com",
//     name: "Amjad Nawaz",
//     phone: "987-654-3210",
//     address: "456 Elm Street, Town, Country",
//   },
//   // Add more dummy user data here
// ];

// const Users = () => {
//   return (
//     <div className="table-responsive">
//       <Table style={{ border: "none" }}>
//         <thead>
//           <tr>
//             <th style={{ border: "none", borderBottom: "1px solid #8080806e" }}>
//               Avatar
//             </th>
//             <th style={{ border: "none", borderBottom: "1px solid #8080806e" }}>
//               Name
//             </th>
//             <th style={{ border: "none", borderBottom: "1px solid #8080806e" }}>
//               Email
//             </th>
//             <th style={{ border: "none", borderBottom: "1px solid #8080806e" }}>
//               Number
//             </th>
//             <th style={{ border: "none", borderBottom: "1px solid #8080806e" }}>
//               Address
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {dummyUsers.map((user, index) => (
//             <tr key={index}>
//               <td>
//                 <img
//                   src={user.avatarUrl}
//                   alt={`Avatar of User ${index + 1}`}
//                   style={{
//                     width: "50px",
//                     height: "50px",
//                     borderRadius: "50%",
//                   }}
//                 />
//               </td>
//               <td>{user.name}</td>
//               <td>{user.email}</td>
//               <td>{user.phone}</td>
//               <td>{user.address}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default Users;
