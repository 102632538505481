import React, { useState } from "react";
import { useEffect } from "react";
import Layout from "../components/layout/Layout";
import "../assets/css/style.css";
import { useNavigate } from "react-router-dom";
import { updategeneralInfo } from "../redux/generalInformation/generalinformationSlice";
import { useDispatch } from "react-redux";
import { getForeman, getProjectNames } from "../apiServices/clientApiServices";
import { toast } from "react-hot-toast";

const GeneralInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState([]);
  const [foreman, setForeman] = useState([]);
  const [loading, setLoading] = useState(true);
  const [generalInfo, setGeneralInfo] = useState({
    project_name: "",
    foreman: "",
    date: "",
  });

  // Get the current date in the format "yyyy-MM-dd"
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
  const day = String(currentDate.getDate()).padStart(2, "0"); // Ensure 2 digits
  const maxDate = `${year}-${month}-${day}`;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGeneralInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { project_name, foreman, date } = generalInfo;
    dispatch(updategeneralInfo({ project_name, foreman, date }));
    navigate("/employeetimecard");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const projectNamesRes = await getProjectNames();
        const foremenRes = await getForeman();

        if (
          projectNamesRes?.data?.success === true &&
          foremenRes?.data?.success === true
        ) {
          setLoading(false);
          setProjectName(projectNamesRes.data.data);
          setForeman(foremenRes.data.data);
        }
      } catch (error) {
        setForeman([]);
        setProjectName([]);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(message);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 align-self-center">
              <h2 className="heading">General Information</h2>
              <p></p>
            </div>
            <div className="col-xl-5 col-lg-6 ml-auto">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Project Name</label>
                      <select
                        className="js-example-placeholder-single selectdata js-states form-control"
                        name="project_name"
                        value={generalInfo.project_name}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Select Project
                        </option>
                        {loading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          projectName?.map((nameslist) => {
                            return (
                              <option
                                key={nameslist._id}
                                value={nameslist.projectName}
                              >
                                {nameslist.projectName}
                              </option>
                            );
                          })
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Foreman</label>
                      <select
                        className="js-example-placeholder-single js-states form-control"
                        name="foreman"
                        value={generalInfo.foreman}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Foreman</option>
                        {loading ? (
                          <option value="" disabled>
                            Loading...
                          </option>
                        ) : (
                          foreman?.map((items, index) => (
                            <option key={items._id} value={items.foreman}>
                              {items.foreman}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={generalInfo.date}
                        onChange={handleChange}
                        required
                        max={maxDate} // Set the max attribute to the current date
                      />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                      Next
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GeneralInformation;
