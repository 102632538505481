import React, { useState } from "react";
import ChangePassword from "./ChangePasswordPage";
import { toast } from "react-hot-toast";
import axios from "axios";
const Otp = ({ email }) => {
  const [userInputOtp, setUserInputOtp] = useState("");
  const [otpMatch, setOtpMatch] = useState(false);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API}/otpVerification`,
        {
          otp: userInputOtp,
          email: email,
        }
      );
      const { success, message } = res.data;
      if (success) {
        toast.success(message);
        setOtpMatch(true);
        setId(res.data.id);
        setLoading(false);
      } else {
        toast.error(message);
        setUserInputOtp("");
        setLoading(false);
      }
    } catch (err) {
      toast.error(`something went wrong`);
      setLoading(false);
    }
  };

  return (
    <>
      {otpMatch ? (
        <ChangePassword id={id} />
      ) : (
        <div className="col-xl-5 col-lg-6 m-auto">
          <div className="card">
            <div className="card-body">
              <h4>OTP Verification</h4>
              <span>Please check your email address for Otp Verification</span>
              <form action="" onSubmit={onSubmit}>
                <div className="form-group mt-3">
                  <label>OTP Code</label>
                  <input
                    type="text"
                    placeholder="Enter your OTP Code"
                    className="form-control"
                    value={userInputOtp}
                    onChange={(e) => {
                      setUserInputOtp(e.target.value);
                    }}
                  />
                </div>
                {loading ? (
                  <button disabled className="btn btn-primary w-100">
                    Loading...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary w-100">
                    Confirm
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Otp;
