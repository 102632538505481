import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employee: "",
  employeeHours: [],
  machineHours: [],
  materialHours: [],
  truckingHours: [],
};

const employeeTimecard = createSlice({
  name: "employeeTimecard",
  initialState,
  reducers: {
    updateEmployeeName: (state, action) => {
      const { EmployeeName } = action.payload;
      state.employee = EmployeeName;
    },

    updateEmployeeHours: (state, action) => {
      const { cards } = action.payload;
      state.employeeHours = cards;
    },
    updateMachineHours: (state, action) => {
      const { cards } = action.payload;
      state.machineHours = cards;
    },
    updateMaterialHours: (state, action) => {
      const { cards } = action.payload;
      state.materialHours = cards;
    },
    updateTruckingHours: (state, action) => {
      const { cards } = action.payload;
      state.truckingHours = cards;
    },
    updateAllcards: (state, action) => {
      const {
        employee,
        employeeHours,
        machineHours,
        materialHours,
        truckingHours,
      } = action.payload;
      state.employee = employee;
      state.employeeHours = employeeHours;
      state.machineHours = machineHours;
      state.materialHours = materialHours;
      state.truckingHours = truckingHours;
    },
  },
});

export const {
  updateEmployeeName,
  updateEmployeeHours,
  updateMachineHours,
  updateMaterialHours,
  updateTruckingHours,
  updateAllcards,
} = employeeTimecard.actions;

export default employeeTimecard.reducer;
