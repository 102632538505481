import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  approvedReport,
  fetchIndividualCard,
  rejectedReport,
} from "../../apiServices/AdminDashboardApiServices";
import { FaCheck, FaTimes } from "react-icons/fa";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const IndidualReport = () => {
  const navigate = useNavigate();
  const cardId = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [userAction, setUserAction] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const { id } = cardId;
      try {
        const result = await fetchIndividualCard(id); // Replace with the actual endpoint
        setData(result);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data."); // You can customize the error message here
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, [cardId]);

  const handleAccept = () => {
    setModalOpen(true);
    setUserAction("accept");
  };

  const handleReject = () => {
    setModalOpen(true);
    setUserAction("reject");
  };

  const handleConfirmAction = async () => {
    if (userAction === "accept") {
      const { id } = cardId;
      const status = "approved";
      try {
        setConfirmLoading(true);
        const result = await approvedReport(id, status);
        if ((result.success = true)) {
          setModalOpen(false);
          navigate("/dashboard/admin/reports");
          setConfirmLoading(false);
          toast.success("User report approved");
        }
      } catch (error) {
        setConfirmLoading(false);
        console.log(error);
      }
    } else if (userAction === "reject") {
      const { id } = cardId;
      const status = "rejected";
      try {
        setConfirmLoading(true);
        const result = await rejectedReport(id, status);
        if ((result.success = true)) {
          setModalOpen(false);
          navigate("/dashboard/admin/pending-reports");
          setConfirmLoading(false);
          toast.success("“User report rejected successfully");
        }
      } catch (error) {
        setConfirmLoading(false);

        console.log(error);
      }
    }

    // Perform the accept or reject action here
    // You can add your logic to send the accept/reject request to the server
    setModalOpen(false);
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (loading) {
    return (
      <div className="card w-100 p-3 m-3">
        <h4 className="bold  p-2">Loading...</h4>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="indidualReportsContainer">
        <div className="row justify-content-center mb-3">
          <div className="col-lg-3">
            <div className="form-group">
              <label>Project Name</label>
              <input
                value={
                  data.userRecord.projectName ? data.userRecord.projectName : ""
                }
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>Foreman</label>
              <input
                value={data.userRecord.foreman ? data.userRecord.foreman : ""}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label>Date: </label>
              <input
                value={formatDate(data?.userRecord?.date)}
                className="form-control"
                disabled
              />
            </div>
          </div>
        </div>

        {data.userRecord?.employeeHours.length > 0 && (
          <div className="table-responsive">
            <h5 className="bold">Employee Hours</h5>
            <table className="table ">
              <thead className="thead-dark">
                <tr>
                  <th>Name</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Total</th>
                  <th>O/L</th>
                </tr>
              </thead>
              <tbody>
                {data.userRecord?.employeeHours?.map((data) => (
                  <tr key={data?.id}>
                    <td>{data?.employeeName}</td>
                    <td>{data?.start}</td>
                    <td>{data?.end}</td>
                    <td>
                      {`${data?.totalTime?.hours}h : ${data?.totalTime?.minutes} m`}
                    </td>
                    <td>{data?.ol}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {data.userRecord?.machineHours.length > 0 && (
          <>
            <h5 className="bold">Machine Hours</h5>
            <div className="table-responsive">
              <table className="table ">
                <thead className="thead-dark">
                  <tr>
                    <th>Asset ID</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Total</th>
                    <th>Description</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {data.userRecord?.machineHours?.map((data) => (
                    <tr key={data.id}>
                      <td>{data?.asset}</td>
                      <td>{data?.start}</td>
                      <td>{data?.end}</td>
                      <td>{data?.total}</td>
                      <td>
                        <textarea
                          value={data?.description}
                          disabled
                          style={{
                            border: "none",
                            height: "30px",
                            width: "100px",
                          }}
                        ></textarea>
                      </td>
                      <td>{data?.employeeName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {/* material Hours */}
        {data.userRecord?.materialHours.length > 0 && (
          <>
            <h5 className="bold">Materials</h5>
            <div className="table-responsive">
              <table className="table ">
                <thead className="thead-dark">
                  <tr>
                    <th>Material/Supplier</th>
                    <th>#Loads</th>
                    <th>Quantity</th>
                    <th>Material Type</th>
                    <th>Slip #</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {data.userRecord?.materialHours?.map((data) => (
                    <tr key={data?.id}>
                      <td>{data?.materialtype}</td>
                      <td>{data.load}</td>
                      <td>{data.quantity}</td>
                      <td>{data.materialtype}</td>
                      <td>{data.slip}</td>
                      <td>{data.employeeName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {/* trucking Hours */}
        {data.userRecord?.truckingHours.length > 0 && (
          <>
            <h5 className="bold">Trucking Hours</h5>
            <div className="table-responsive">
              <table className="table ">
                <thead className="thead-dark">
                  <tr>
                    <th>Trucking</th>
                    <th>Driver</th>
                    <th>Start Mileage</th>
                    <th>End Mileage</th>
                    <th>Total Mileage</th>
                    <th> Start Time </th>
                    <th>End Time</th>
                    <th>Total Time</th>
                  </tr>
                </thead>
                <tbody>
                  {data.userRecord?.truckingHours?.map((data) => (
                    <tr key={data.id}>
                      <td>{data.truck}</td>
                      <td>{data?.employeeName}</td>

                      <td>{data.startMileage}</td>
                      <td>{data.endMileage}</td>
                      <td>{data.totalMileage}</td>
                      <td>{data.startTime}</td>
                      <td>{data.endTime}</td>
                      <td>
                        {`${data.totalTime.hours}h:${data.totalTime.minutes}m`}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        <>
          {data?.userRecord.status === "pending" && (
            <div className="d-flex justify-content-between w-100 p-4">
              <button className="btn btn-danger" onClick={handleReject}>
                <FaTimes className="mr-2" /> Reject
              </button>
              <button className="btn btn-success" onClick={handleAccept}>
                <FaCheck className="mr-2" /> Approve
              </button>
            </div>
          )}
        </>
        {isModalOpen && (
          <>
            <Modal
              show={isModalOpen}
              onHide={setModalOpen}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>Logout</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to {userAction}?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalOpen(false)}>
                  Close
                </Button>
                {confirmLoading ? (
                  <Button variant="primary" onClick={handleConfirmAction}>
                    Loading...
                  </Button>
                ) : (
                  <Button variant="primary" onClick={handleConfirmAction}>
                    Confirm
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default IndidualReport;
