import React from "react";
import Header from "./Header";

const Layout = ({ children }) => {
  return (
    <>
      <main>
        <Header />
        <main style={{ minHeight: "70vh" }}>{children}</main>
      </main>
    </>
  );
};

export default Layout;
