import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  AiOutlineUser,
  AiOutlineHome,
  AiFillFolderOpen,
  AiOutlineLogout,
} from "react-icons/ai";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
function UserDashboardMenu() {
  const [isActiveTab, setIsActiveTab] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/");
    toast.success("User signed out successfully");
  };

  return (
    <aside
      className={`dashboard-sidebar ${isActiveTab ? "responsive-visible" : ""}`}
    >
      <img src="/images/lightLogo.svg" alt="Logo" className="brand-logo" />
      <div className="list-group">
        <div>
          {/* <h4 className="mb-4">SUPER ADMIN</h4> */}
          <NavLink
            to="/user-dashboard/home"
            className="list-group-item"
            onClick={() => {
              setIsActiveTab(!isActiveTab);
            }}
          >
            <span className="icon">
              <AiOutlineHome className="mr-2" />
            </span>
            <span className="text">Home</span>
          </NavLink>
          <NavLink
            to="/user-dashboard/profile"
            className="list-group-item"
            onClick={() => {
              setIsActiveTab(!isActiveTab);
            }}
          >
            <span className="icon">
              <AiOutlineUser className="mr-2" />
            </span>
            <span className="text">Profile</span>
          </NavLink>
          <NavLink
            to="/generalinformation"
            className="list-group-item"
            onClick={() => {
              setIsActiveTab(!isActiveTab);
            }}
          >
            <span className="icon">
              <AiFillFolderOpen className="mr-2" />
            </span>
            <span className="text">Application</span>
          </NavLink>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "7px",
            padding: "10px",
            cursor: "pointer",
            width: "90%",
          }}
        >
          <span className="list-group-item" onClick={(e) => handleShow(e)}>
            <span className="icon">
              <AiOutlineLogout className="mr-2" />
            </span>
            <span className="text">Logout</span>
          </span>
        </div>
      </div>
      <span
        className="toggle-btn"
        onClick={() => {
          setIsActiveTab(!isActiveTab);
        }}
      >
        <img
          src="/images/ic_arrow_right.svg"
          alt="Toggle Sidebar"
          className="arrow"
        />
        <img src="/images/ic_menu.svg" alt="Toggle Sidebar" className="menu" />
      </span>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          style={{ top: "30vh" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to Logout?</Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <Button variant="secondary" onClick={() => handleClose(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleLogout}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </aside>
  );
}

export default UserDashboardMenu;
