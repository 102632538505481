import React from "react";

import { Outlet } from "react-router-dom";
import UserDashboardMenu from "./pages/userDashboardMenu";
import UserDashboardHeader from "./pages/userDashboardHeader";

function UserDashboard() {
  return (
    <>
      <section className="dashboard-layout">
        <UserDashboardMenu />
        <main className="main-content">
          <UserDashboardHeader />
          <section className="main-scroll-wrapper">
            <div className="inner">
              <Outlet />
            </div>
          </section>
        </main>
      </section>
    </>
  );
}

export default UserDashboard;
