import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import { dashboardLogin } from "../../apiServices/AdminDashboardApiServices";
import Cookies from "js-cookie";
import { BsEye, BsEyeSlash } from "react-icons/bs"; // Import Bootstrap icons for show/hide

const DahboardLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      return toast.error("Please enter your email and password");
    }

    try {
      setLoading(true);
      const response = await dashboardLogin(email, password);
      if (response.data.success === true) {
        Cookies.set("adminAuth", response.data.token);
        navigate("/dashboard/admin/users");
        toast.success(response.data.message);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <section className="section-padding">
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-xl-4 col-lg-6 align-self-center">
              <h2 className="heading">Admin Login</h2>
              <p>
                The access to this dashboard is restricted solely to
                administrators. Authorized personnel with administrative
                privileges are the only individuals permitted to enter and
                interact with the content and features presented within this
                dashboard.
              </p>
            </div>
            <div className="col-xl-5 col-lg-6 ml-auto">
              <div className="card">
                <div className="card-body">
                  <form action="" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <span
                          className="input-group-text"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        >
                          {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </div>
                    </div>
                    {loading ? (
                      <button className="btn btn-primary w-100" disabled>
                        Loading...
                      </button>
                    ) : (
                      <button className="btn btn-primary w-100">Login</button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DahboardLogin;
