import React from "react";
import Layout from "../components/layout/Layout";
import { NavLink } from "react-router-dom";
const PageNotFound = () => {
  return (
    <Layout>
      <div className="d-flex align-items-center justify-content-center">
        <div className="text-center">
          <div className="display-3 fw-bold">404</div>
          <p className="fs-3">
            <span className="text-danger">Opps!</span> Page not found.
          </p>
          <p className="lead">The page you’re looking for doesn’t exist.</p>
          <NavLink to="/">
            <button className="btn btn-primary"> Go Home</button>
          </NavLink>
        </div>
      </div>
    </Layout>
  );
};

export default PageNotFound;
