import React, { useEffect, useState } from "react";
import plusImage from "../../assets/images/feather-plus-square.svg";
import "../../assets/css/style.css";
import { useDispatch, useSelector } from "react-redux";
import { updateMachineHours } from "../../redux/employeeTimecard/timeCardSlice";
import { getMachineHoursAsset } from "../../apiServices/clientApiServices";
import { toast } from "react-hot-toast";

const MachineHoursCard = ({ generalreviewStatus, employeeTimeCardStatus }) => {
  const dispatch = useDispatch();
  const { machineHours } = useSelector((state) => state.employeeTimecardData);
  const [employeeName, setEmployeeName] = useState("");
  const [asset, setAsset] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    setEmployeeName(user?.name);
  }, [user]);

  useEffect(() => {
    const fetchAssetData = async () => {
      try {
        setLoading(true);
        const response = await getMachineHoursAsset();
        if (response.data.success === true) {
          setAsset(response.data.data);
          console.log(response.data.data, "response %%");
        }
        setLoading(false);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setAsset([]);
        toast.error(message);
      }
    };

    fetchAssetData();
  }, []);
  const handleClick = (e) => {
    e.preventDefault();
    const newCard = {
      id: machineHours.length + 1,
      asset: "",
      start: "",
      end: "",
      total: "",
      error: false,
      description: "",
      employeeName: employeeName,
    };
    const updatedCards = [...machineHours, newCard];
    dispatch(updateMachineHours({ cards: updatedCards })); // Dispatch the updated cards to Redux
  };

  const removeCard = (e, cardId) => {
    e.preventDefault();
    const updatedCards = machineHours
      .filter((card) => card.id !== cardId)
      .map((card, index) => {
        return {
          ...card,
          id: index + 1,
        };
      });
    dispatch(updateMachineHours({ cards: updatedCards })); // Dispatch the updated cards to Redux
  };

  const handleInputValues = (e, index, field) => {
    const { value } = e.target;
    const updatedCards = machineHours.map((card, cardIndex) => {
      if (cardIndex === index) {
        return {
          ...card,
          [field]: value,
        };
      }
      return card;
    });

    if (field === "end") {
      const start = Number(updatedCards[index].start);
      const end = Number(value);

      if (!isNaN(start) && !isNaN(end)) {
        if (end < start || end - start > 24) {
          updatedCards[index].error = true; // Set the error flag for the card
          updatedCards[index].total = 0; // Set total to zero if end is less than start or total exceeds 24
        } else {
          updatedCards[index].error = false; // Clear the error flag for the card
          updatedCards[index].total = end - start;
        }
      } else {
        updatedCards[index].error = false; // Clear the error flag for the card
        updatedCards[index].total = "";
      }
    } else if (field === "start") {
      updatedCards[index].error = false; // Clear the error flag for the card
      updatedCards[index].total = "";
    }

    dispatch(updateMachineHours({ cards: updatedCards })); // Dispatch the updated cards to Redux
  };

  return (
    <div>
      <div style={{ display: `${employeeTimeCardStatus ? "inline" : "none"}` }}>
        {machineHours?.map((card, index) => (
          <div key={card.id}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Asset</label>
                    <select
                      id="inputAsset"
                      className="form-control"
                      value={card.asset}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "asset");
                      }}
                    >
                      <option value="" disabled>
                        {loading ? "Loading..." : "Choose..."}
                      </option>
                      {asset?.length > 0 ? (
                        asset.map((option) => (
                          <option
                            key={option._id}
                            value={option.machineHoursAssets}
                          >
                            {option.machineHoursAssets}
                          </option>
                        ))
                      ) : (
                        <option disabled>No data found</option>
                      )}
                    </select>
                    {card.asset === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </div>
                </div>
              </div>
              {/* row2 */}
              <div className="row">
                <div className="row">
                  <div className="col">
                    <label>Start</label>
                    <input
                      id="validationDefault05"
                      min={0}
                      type="number"
                      name="tentacles"
                      required
                      className="form-control"
                      value={card.start}
                      onChange={(e) => {
                        handleInputValues(e, index, "start");
                      }}
                    />
                    {card.start === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </div>
                  <div className="col">
                    <label>End</label>
                    <input
                      type="number"
                      className={`form-control ${
                        card.error ? "is-invalid" : ""
                      }`}
                      value={card.end}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "end");
                      }}
                    />
                    {card.end === "" && (
                      <span className="required-fields">Required</span>
                    )}
                    {card.error && (
                      <div className="invalid-feedback">
                        End time must be greater than start time and total must
                        not exceed 24 hours.
                      </div>
                    )}
                  </div>
                  <div className="col">
                    <label>Total</label>
                    <input
                      type="number"
                      className="form-control"
                      value={card.total}
                      disabled
                      onChange={(e) => {
                        handleInputValues(e, index, "total");
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      type="text"
                      id="inputAsset"
                      className="form-control"
                      value={card.description}
                      required
                      rows="3"
                      cols="20"
                      onChange={(e) => {
                        handleInputValues(e, index, "description");
                      }}
                    ></textarea>
                    {card.description === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="removeBtn">
              <button onClick={(e) => removeCard(e, card.id)}>Remove</button>
            </div>
          </div>
        ))}

        {/* Button to add a new card */}
        <button
          className="btn btn-default w-100"
          id="machinebtn"
          onClick={handleClick}
        >
          <img src={plusImage} alt="btnImg" />
          Machine Hours
        </button>
      </div>
      {/* below card is for generalReview */}
      <div style={{ display: `${generalreviewStatus ? "inline" : "none"}` }}>
        <h4 className="bold">Machine Hours</h4>
        <div className="table-wrapper">
          <table className="table machine-table">
            <colgroup>
              <col width="23%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="28%" />
              <col width="14%" />
              <col width="5%" />
            </colgroup>
            <thead>
              <tr>
                <th>Asset ID </th>
                <th>Start</th>
                <th>End</th>
                <th>Total</th>
                <th>Description of Work Performed</th>
                <th>Name</th>

                <th>Edit</th>
              </tr>
            </thead>

            <tbody>
              {machineHours?.map((data, index) => (
                <tr key={data?.id}>
                  <td>
                    <select
                      id="inputAsset"
                      className="form-control"
                      value={data.asset}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "asset");
                      }}
                    >
                      <option value="" disabled>
                        {loading ? "Loading..." : "Choose..."}
                      </option>
                      {asset?.length > 0 ? (
                        asset.map((option) => (
                          <option
                            key={option._id}
                            value={option.machineHoursAssets}
                          >
                            {option.machineHoursAssets}
                          </option>
                        ))
                      ) : (
                        <option disabled>No data found</option>
                      )}
                    </select>
                    {data.asset === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      required
                      value={data?.start}
                      onChange={(e) => handleInputValues(e, index, "start")}
                    />
                    {data.start === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className={`form-control ${
                        data.error ? "is-invalid" : ""
                      }`}
                      value={data?.end}
                      onChange={(e) => handleInputValues(e, index, "end")}
                      required
                    />
                    {data.end === "" && (
                      <span className="required-fields">Required</span>
                    )}
                    {data.error && (
                      <div
                        className="invalid-feedback"
                        style={{ fontSize: "10px" }}
                      >
                        End time greater than start & total less than 24
                      </div>
                    )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={data?.total}
                      onChange={(e) => handleInputValues(e, index, "total")}
                      required
                      disabled
                    />
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      rows="1"
                      cols="1"
                      defaultValue={data?.description}
                      onChange={(e) =>
                        handleInputValues(e, index, "description")
                      }
                      required
                    ></textarea>
                    {data.description === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      className={`js-example-placeholder-single js-states form-control`}
                      value={data?.employeeName}
                      onChange={(e) =>
                        handleInputValues(e, index, "employeeName")
                      }
                      disabled
                      required
                    />
                  </td>
                  <td className="print--hidden text-center">
                    <button
                      className="btn"
                      onClick={(e) => removeCard(e, data.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}

              <tr className="addtr">
                <td colSpan="7">
                  <button
                    className="btn btn-default w-100"
                    id="addrow"
                    onClick={handleClick}
                  >
                    <img src={plusImage} alt="+" />
                    Machine Time
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MachineHoursCard;
