import React, { useState } from "react";
import "../assets/css/style.css";
import Layout from "../components/layout/Layout";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs"; // Import Bootstrap icons for show/hide

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validation code...

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/registration`,
        { name, email, phone, password }
      );
      if (res.data.success === true) {
        navigate("/");
        toast.success(res.data.message);
        setLoading(false);
      } else {
        toast.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setLoading(false);
      toast.error(message);
      return;
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <section className="section-padding">
        <div className="container">
          <div className="row d-flex justify-content-evenly">
            <div className="col-xl-4 col-lg-6 align-self-center">
              <h2 className="heading">Sign Up</h2>
              <p></p>
            </div>
            <div className="col-xl-5 col-lg-6 ml-auto">
              <div className="card">
                <div className="card-body">
                  <form action="" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        required
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Phone</label>
                      <input
                        type="number"
                        className="form-control"
                        value={phone}
                        required
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          value={password}
                          required
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <span
                          className="input-group-text"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        >
                          {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </div>
                    </div>
                    {loading ? (
                      <button className="btn btn-primary w-100" disabled>
                        Loading
                      </button>
                    ) : (
                      <button className="btn btn-primary w-100">Sign up</button>
                    )}
                    <div>
                      <p className="text-center text-muted mt-3 mb-0">
                        Already have an account?
                        <Link to="/" className="fw-bold text-body">
                          <u>Login </u>
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Signup;
