// api.js

import axios from "axios";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_API;

const apiClient = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    try {
      const token = await Cookies.get("adminAuth");
      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      console.error("Error retrieving token:", error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// dashboard login
export const dashboardLogin = async (email, password) =>
  await apiClient.post("/admin-login", {
    email,
    password,
  });

export const adminVarifyToken = async (adminToken) => {
  try {
    const response = await apiClient.post("admin-verify-token", {
      adminToken,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const getPendingReports = async () => {
  try {
    const response = await apiClient.get("/pending-reports");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUsers = async () => {
  try {
    const response = await apiClient.get("/get-all-users");
    return response.data;
  } catch (error) {
    // Handle errors here, e.g., log, show a notification, etc.
    throw error;
  }
};
export const fetchAllReports = async () => {
  try {
    const response = await apiClient.get("/all-user-records");
    return response.data;
  } catch (error) {
    // Handle errors here, e.g., log, show a notification, etc.
    throw error;
  }
};
export const fetchIndividualCard = async (id) => {
  try {
    const response = await apiClient.get(`/get-individual-records/${id}`);
    return response.data;
  } catch (error) {
    // Handle errors here, e.g., log, show a notification, etc.
    throw error;
  }
};
export const approvedReport = async (id, status) => {
  try {
    const response = await apiClient.put("/user-record-approved", {
      id: id,
      status: status,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const rejectedReport = async (id, status) => {
  try {
    const response = await apiClient.put("/user-record-rejected", {
      id: id,
      status: status,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//api request for project names
export const getProjectNames = async () => {
  try {
    const response = await apiClient.get("/projectname");
    return response.data; // Assuming your API response data is stored here
  } catch (error) {
    throw error;
  }
};
export const postProjectNames = async (fieldValue) => {
  try {
    const { projectName } = fieldValue;
    const response = await apiClient.post("/projectname", {
      projectName: projectName,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateProjectName = async (id, value) => {
  try {
    const response = await apiClient.put("/projectname", {
      id: id,
      value: value,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteProjectName = async (id) => {
  try {
    const response = await apiClient.delete("/projectname", {
      data: {
        id: id,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//api reques for foreman

export const getForeman = async () => {
  try {
    const response = await apiClient.get("/foreman");
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const postForeman = async (fieldValue) => {
  try {
    const { foreman } = fieldValue;
    const response = await apiClient.post("/foreman", {
      foreman: foreman,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateForeman = async (id, value) => {
  try {
    const response = await apiClient.put("/foreman", {
      id: id,
      value: value,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteForeman = async (id) => {
  try {
    const response = await apiClient.delete("/foreman", {
      data: {
        id: id,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// crud api for machineAssets
export const getMachineAssets = async () => {
  try {
    const response = await apiClient.get("/machine-hours-asset");
    return response.data; // Assuming your API response data is stored here
  } catch (error) {
    throw error;
  }
};
export const postMachineAssets = async (value) => {
  try {
    const { machineAsset } = value;
    const response = await apiClient.post("/machine-hours-asset", {
      machineHoursAssets: machineAsset,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateMachineAssets = async (id, value) => {
  try {
    const response = await apiClient.put("/machine-hours-asset", {
      id: id,
      value: value,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteMachineAssets = async (id) => {
  try {
    const response = await apiClient.delete("/machine-hours-asset", {
      data: {
        id: id,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// crud apis for material supplier
export const getMaterialSupplier = async () => {
  try {
    const response = await apiClient.get("/material-hours-supplier");
    return response.data; // Assuming your API response data is stored here
  } catch (error) {
    throw error;
  }
};
export const postMaterialSupplier = async (value) => {
  try {
    const { materialSupplierName } = value;
    const response = await apiClient.post("/material-hours-supplier", {
      materialSupplier: materialSupplierName,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateMaterialSupplier = async (id, value) => {
  try {
    console.log(id, value);
    const response = await apiClient.put("/material-hours-supplier", {
      id: id,
      value: value,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteMaterialSupplier = async (id) => {
  try {
    const response = await apiClient.delete("/material-hours-supplier", {
      data: {
        id: id,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// crud api for material type
export const getMaterialType = async () => {
  try {
    const response = await apiClient.get("/material-hours-type");
    return response.data; // Assuming your API response data is stored here
  } catch (error) {
    throw error;
  }
};
export const postMaterialType = async (value) => {
  try {
    const { materialType } = value;
    const response = await apiClient.post("/material-hours-type", {
      materialType: materialType,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateMaterialType = async (id, value) => {
  try {
    const response = await apiClient.put("/material-hours-type", {
      id: id,
      value: value,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteMaterialType = async (id) => {
  try {
    const response = await apiClient.delete("/material-hours-type", {
      data: {
        id: id,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
// crud api for truck dropdown
export const getTrucksDropdown = async () => {
  try {
    const response = await apiClient.get("/trucking-hours-truck");
    return response.data; // Assuming your API response data is stored here
  } catch (error) {
    throw error;
  }
};
export const postTruckDropdown = async (value) => {
  try {
    const { truckListValue } = value;
    const response = await apiClient.post("/trucking-hours-truck", {
      truckListValue: truckListValue,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateTruckDropdown = async (id, value) => {
  try {
    const response = await apiClient.put("/trucking-hours-truck", {
      id: id,
      value: value,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteTruckDropdown = async (id) => {
  try {
    const response = await apiClient.delete("/trucking-hours-truck", {
      data: {
        id: id,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
