import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getUserDetails,
  updateUserDetails,
} from "../../apiServices/clientApiServices";
import { toast } from "react-hot-toast";

const Profile = () => {
  const userId = useSelector((state) => state.user?.user?.userId);

  const [editMode, setEditMode] = useState(false);
  const [userData, setUserData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (userId) {
      async function fetchUserDetails() {
        try {
          setLoading(true);
          const response = await getUserDetails(userId);
          if (response.data.success === true) {
            setUserData(response.data.data);
            setEditedData(response.data.data);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          toast.error(message);
        }
      }

      fetchUserDetails();
    }
  }, [userId]);
  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setEditedData({ ...userData });
  };

  const handleSaveClick = async () => {
    try {
      setLoading(true);
      const response = await updateUserDetails(userId, editedData);
      if (response.data.success === true) {
        setLoading(false);
        setEditMode(false);
        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      setEditMode(false);
      setLoading(false);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  if (loading) {
    return (
      <div className="d-flex justify-content-center p-5">
        <div className="spinner-border" role="status"></div>
      </div>
    );
  }
  return (
    <div className="p-5">
      <div>
        <h5>{editMode ? "Edit Profile" : "Profile"}</h5>
      </div>
      <hr />
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "2rem",
          }}
        >
          <img
            src="/images/userAvatar.png"
            alt="Avatar"
            className="rounded-circle"
            style={{ width: "120px", height: "120px" }}
          />
          <div>
            <strong className="font-weight-bold mb-1">{userData?.name}</strong>
            <p className="text-muted small mb-1">{userData?.email}</p>
          </div>
        </div>
        {editMode ? (
          <div>
            <div className="col-6 mt-3">
              <strong>Name</strong>
              <input
                type="text"
                id="name"
                name="name"
                value={editedData?.name}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="col-6 mt-3">
              <strong>Email</strong>
              <input
                type="text"
                name="email"
                value={editedData?.email}
                onChange={handleInputChange}
                className="form-control"
                disabled
              />
            </div>
            <div className="col-6 mt-3">
              <strong>Phone</strong>
              <input
                type="text"
                name="phone"
                value={editedData.phone}
                onChange={handleInputChange}
                className="form-control"
                min={3}
                max={15}
              />
            </div>
            <div className="col-6 mt-3">
              <strong>Address</strong>
              <input
                type="text"
                name="address"
                value={editedData?.address}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
        ) : (
          <div>
            <h3 className="mt-4">Personal Information</h3>
            <strong>Name</strong>
            <p className="font-weight-bold ">{userData?.name}</p>
            <strong>Email</strong>
            <p className="text-muted small ">{userData?.email}</p>

            <strong>Phone</strong>
            <p className="text-muted">{userData?.phone}</p>
            <strong>Address</strong>
            <p className="text-muted">{userData?.address}</p>
          </div>
        )}
      </div>
      <div className="mt-4">
        {editMode ? (
          <>
            <button
              className="btn btn-primary  "
              onClick={handleSaveClick}
              style={{ marginRight: "50px" }}
            >
              Save
            </button>
            <button
              className="btn btn-secondary mr-5"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </>
        ) : (
          <button className="btn btn-primary" onClick={handleEditClick}>
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default Profile;
