import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectName: "",
  foreman: "",
  date: "",
};

const generalInfo = createSlice({
  name: "generalInfo",
  initialState,
  reducers: {
    updategeneralInfo: (state, action) => {
      const { project_name, foreman, date } = action.payload;
      state.projectName = project_name;
      state.foreman = foreman;
      state.date = date;
    },
  },
});

export const { updategeneralInfo } = generalInfo.actions;

export default generalInfo.reducer;
