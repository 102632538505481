import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userCredentials, thunkAPI) => {
    const { email, password } = userCredentials;
    try {
      const request = await axios.post(
        `${process.env.REACT_APP_API}/login`,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = request;

      if (response.data.success === true) {
        Cookies.set("token", response?.data?.token);
      }
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    user: null,
    isError: null,
    message: null,
    isSuccess: false,
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.isError = null;
        state.message = null;
        state.isSuccess = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.isError = null;
        state.message = action.payload.message;
        state.isSuccess = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },
  },
});
export const { addUser } = userSlice.actions;

export default userSlice.reducer;
