import React from "react";
import checkImage from "../assets/images/check.svg";

import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <section className="section-padding">
          <div className="container">
            <div className="row min-vh-100 align-items-center">
              <div className="col-xl-4 col-lg-6 mx-auto">
                <div
                  className="alert alert-dismissible px-3 fade show card"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close m-2 "
                    aria-label="Close"
                    onClick={() => navigate("/generalinformation")}
                  ></button>
                  <div className="card-body text-center">
                    <img
                      src={checkImage}
                      className="mb-4"
                      width="130"
                      alt="tickImg"
                    />
                    <h1>Thank you for submission</h1>
                    <p>
                      {/* Varius duis at consectetur lorem donec massa sapien
                      faucibus. */}
                    </p>
                    <button
                      href="#"
                      className="btn btn-primary"
                      onClick={() => navigate("/user-dashboard/home")}
                    >
                      Home
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ThankYou;
