import { useState } from "react";
import Layout from "../components/layout/Layout";
import { useForm } from "react-hook-form";
import Otp from "./Otp";
import axios from "axios";
import { toast } from "react-hot-toast";

// import axios from "axios";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (userData) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API}/forgetpassword`,
        {
          email: userData.email,
        }
      );
      const { success, message } = res.data;
      if (!success) {
        toast.error(message);
        setEmail("");
        setLoading(false);
      } else {
        toast.success(message);
        setLoading(false);
        setEmail(userData.email);
      }
    } catch (err) {
      toast.error(`user not found: ${userData.email}`);
      setLoading(false);
    }
  };
  return (
    <>
      <Layout>
        {!email && (
          <div className="col-xl-5 col-lg-6 m-auto">
            <div className="card">
              <div className="card-body">
                <h4>Find Your Account</h4>
                <span>
                  Please enter your email address to search for your account.
                </span>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group mt-3">
                    <label>Email</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Please enter a valid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">
                        {errors.email.message}
                      </div>
                    )}
                  </div>
                  {loading ? (
                    <button disabled className="btn btn-primary w-100">
                      Loading...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary w-100">
                      Submit
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
        {email && <Otp email={email} />}
      </Layout>
    </>
  );
};

export default ForgetPassword;
