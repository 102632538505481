import React from "react";
function DashboardHeader() {
  return (
    <>
      <header className="dashboard-header">
        <div className="nav-greeting">
          <h2 className="title">
            Welcome to <span className="name">Admin Panel</span>
          </h2>
          <p className="tag">{/* Find your best service here. */}</p>
        </div>
        <div className="nav-buttons">
          {/* <button
            className="notification_button"
            aria-label="Notification Button"
          >
            <img src="/images/ic_bell.svg" alt="Bell Icon" />
          </button> */}
          {/* <button className="avatar_button" aria-label="User Button">
            <img src="/images/img_avatar.png" alt="Bell Icon" />
          </button> */}
        </div>
      </header>
    </>
  );
}

export default DashboardHeader;
