import React, { useEffect, useState } from "react";
import plusImage from "../../assets/images/feather-plus-square.svg";
import "../../assets/css/style.css";
import { useSelector, useDispatch } from "react-redux";
import { updateMaterialHours } from "../../redux/employeeTimecard/timeCardSlice";
import { toast } from "react-hot-toast";
import {
  getMaterialSupplier,
  getMaterialType,
} from "../../apiServices/clientApiServices";

const MaterialHoursCard = ({ generalreviewStatus, employeeTimeCardStatus }) => {
  const dispatch = useDispatch();
  const { materialHours } = useSelector((state) => state.employeeTimecardData);
  const [employeeName, setEmployeeName] = useState("");
  const [loading, setLoading] = useState(true);
  const [supplier, setSupplier] = useState([]);
  const [materialType, setMaterialType] = useState([]);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    setEmployeeName(user?.name);
  }, [user]);

  useEffect(() => {
    const fetchAssetData = async () => {
      try {
        setLoading(true);
        const supplierRes = await getMaterialSupplier();
        const MaterialTypeRes = await getMaterialType();
        if (
          supplierRes.data.success === true &&
          MaterialTypeRes.data.success === true
        ) {
          setSupplier(supplierRes.data.data);
          setMaterialType(MaterialTypeRes.data.data);
          setLoading(false);
        }
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setSupplier([]);
        toast.error(message);
      }
    };

    fetchAssetData();
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    const newCard = {
      id: materialHours.length + 1,
      material: "",
      load: "",
      quantity: "",
      materialtype: "",
      slip: "",
      employeeName: employeeName,
    };
    const updatedCards = [...materialHours, newCard];
    dispatch(updateMaterialHours({ cards: updatedCards }));
  };

  const removeCard = (e, cardId) => {
    e.preventDefault();
    const updatedCards = materialHours
      .filter((card) => card.id !== cardId)
      .map((card, index) => ({
        ...card,
        id: index + 1,
      }));
    dispatch(updateMaterialHours({ cards: updatedCards }));
  };

  const handleInputValues = (e, index, field) => {
    const { value } = e.target;
    const updatedCards = materialHours.map((card, cardIndex) =>
      cardIndex === index ? { ...card, [field]: value } : card
    );
    dispatch(updateMaterialHours({ cards: updatedCards }));
  };

  return (
    <div>
      <div style={{ display: `${employeeTimeCardStatus ? "inline" : "none"}` }}>
        {materialHours?.map((card, index) => (
          <div key={card?.id}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>MATERIAL/SUPPLIER</label>
                    <select
                      id="inputMaterialSupplier"
                      className="form-control"
                      value={card?.material}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "material");
                      }}
                    >
                      <option value="" disabled>
                        {loading ? "Loading..." : "Choose..."}
                      </option>
                      {supplier?.length > 0 ? (
                        supplier.map((option) => (
                          <option key={option._id} value={option.supplier}>
                            {option.supplier}
                          </option>
                        ))
                      ) : (
                        <option disabled>No data found</option>
                      )}
                    </select>
                    {card.material === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>LOADS</label>
                  <input
                    type="number"
                    className="form-control"
                    value={card?.load}
                    onChange={(e) => {
                      handleInputValues(e, index, "load");
                    }}
                  />
                  {card.load === "" && (
                    <span className="required-fields">Required</span>
                  )}
                </div>

                <div className="col">
                  <label>QUANTITY</label>
                  <input
                    type="number"
                    className="form-control"
                    value={card?.quantity}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "quantity");
                    }}
                  />
                  {card.quantity === "" && (
                    <span className="required-fields">Required</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>MATERIAL TYPE</label>
                  <select
                    id="inputMaterialType"
                    className="form-control"
                    value={card?.materialtype}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "materialtype");
                    }}
                  >
                    <option value="" disabled>
                      {loading ? "Loading..." : "Choose..."}
                    </option>
                    {materialType?.length > 0 ? (
                      materialType.map((option) => (
                        <option key={option._id} value={option.materialType}>
                          {option.materialType}
                        </option>
                      ))
                    ) : (
                      <option disabled>No data found</option>
                    )}
                  </select>
                  {card.materialtype === "" && (
                    <span className="required-fields">Required</span>
                  )}
                </div>

                <div className="col">
                  <label>SLIP #</label>
                  <input
                    type="text"
                    className="form-control"
                    value={card?.slip}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "slip");
                    }}
                  />
                  {card.slip === "" && (
                    <span className="required-fields">Required</span>
                  )}
                </div>
              </div>
            </div>
            {/* Button to remove a card */}
            <div className="removeBtn mt-2">
              <button onClick={(e) => removeCard(e, card.id)}>Remove</button>
            </div>
          </div>
        ))}

        {/* Button to add a new card */}
        <button
          className="btn btn-default w-100"
          id="materialbtn"
          onClick={handleClick}
        >
          <img src={plusImage} alt="btnImg" />
          Material
        </button>
      </div>
      {/* below card is for generalReview card */}
      <div style={{ display: `${generalreviewStatus ? "inline" : "none"}` }}>
        <h4 className="bold">Materials</h4>
        <div className="table-wrapper">
          <table className="table materials-table">
            <colgroup>
              <col width="20%" />
              <col width="10%" />
              <col width="10%" />
              <col width="18%" />
              <col width="8%" />
              <col width="18%" />
              <col width="15%" />
              <col width="6%" />
              <col width="6%" />
            </colgroup>
            <thead>
              <tr>
                <th>Material/Supplier</th>
                <th>#Loads</th>
                <th>Quantity</th>
                <th>Material Type</th>
                <th>Slip #</th>
                <th>Name</th>
                <th>Edit</th>
                <th className="print--hidden"></th>
              </tr>
            </thead>
            <tbody>
              {materialHours?.map((data, index) => (
                <tr key={data?.id}>
                  <td>
                    <select
                      id="inputMaterialSupplier"
                      className="form-control"
                      value={data?.material}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "material");
                      }}
                    >
                      <option value="" disabled>
                        {loading ? "Loading..." : "Choose..."}
                      </option>
                      {supplier?.length > 0 ? (
                        supplier.map((option) => (
                          <option key={option._id} value={option.supplier}>
                            {option.supplier}
                          </option>
                        ))
                      ) : (
                        <option disabled>No data found</option>
                      )}
                    </select>
                    {data.material === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="Number"
                      className="form-control"
                      value={data.load}
                      onChange={(e) => {
                        handleInputValues(e, index, "load");
                      }}
                      required
                    />
                    {data.load === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      value={data.quantity}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "quantity");
                      }}
                    />
                    {data.quantity === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>

                  <td>
                    <select
                      id="inputMaterialType"
                      className="form-control"
                      value={data?.materialtype}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "materialtype");
                      }}
                    >
                      <option value="" disabled>
                        {loading ? "Loading..." : "Choose..."}
                      </option>
                      {materialType?.length > 0 ? (
                        materialType.map((option) => (
                          <option key={option._id} value={option.materialType}>
                            {option.materialType}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>
                          No data found
                        </option>
                      )}
                    </select>
                    {data.materialtype === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={data.slip}
                      required
                      onChange={(e) => {
                        handleInputValues(e, index, "slip");
                      }}
                    />
                    {data.slip === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>

                  {/* <td>
                          <select
                            id="inputAsset"
                            className="form-control"
                            value={data.asset}
                            required
                            onChange={(e) => {
                              handleInputMaterialChange(e, index, "asset");
                            }}
                          >
                            <option value="">Choose...</option>
                            <option value="Asset A">Asset A</option>
                            <option value="Asset B">Asset B</option>
                            <option value="Asset C">Asset C</option>
                            <option value="Asset D">Asset D</option>
                          </select>
                        </td> */}
                  <td>
                    <input
                      // id="inputAsset"
                      className="form-control"
                      required
                      defaultValue={data.employeeName}
                      onChange={(e) => {
                        handleInputValues(e, index, "employeeName");
                      }}
                      disabled
                    />
                  </td>
                  <td className="print--hidden text-center">
                    <button
                      className="btn"
                      onClick={(e) => removeCard(e, data.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="addtr">
                <td colSpan="8">
                  <button
                    className="btn btn-default w-100"
                    id="addrow"
                    onClick={handleClick}
                  >
                    <img src={plusImage} alt="+" />
                    Materials
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MaterialHoursCard;
