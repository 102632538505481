import React from "react";

function Projects() {
  return (
    <div className="card w-100 p-3 m-3">
      <h3>Projects</h3>
    </div>
  );
}

export default Projects;
