import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import "../assets/css/style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmployeeHoursCard from "./cards/EmployeeHoursCard";
import MachineHoursCard from "./cards/MachineHoursCard";
import MaterialHoursCard from "./cards/MaterialHoursCard";
import TruckingHoursCard from "./cards/TruckingHoursCard";
import { toast } from "react-hot-toast";
import {
  getForeman,
  getProjectNames,
  postUserRecords,
} from "../apiServices/clientApiServices";
import { updateAllcards } from "../redux/employeeTimecard/timeCardSlice";

const GeneralReview = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { employeeHours, machineHours, materialHours, truckingHours } =
    useSelector((state) => state.employeeTimecardData); // get data from redux store
  const { projectName, foreman, date } = useSelector(
    (state) => state.generalInfo
  );

  const [projectNameOptions, setProjectNameOptions] = useState([]);
  const [foremanOptions, setForemanOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState("");
  const [generalData, setGeneralData] = useState({
    projectName: projectName,
    foreman: foreman,
    date: date,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        setLoading(true);
        const apiCalls = [getProjectNames(), getForeman()];
        const responses = await Promise.all(apiCalls);
        const projectNameRes = responses[0];
        const foremanRes = responses[1];
        if (projectNameRes.data.success) {
          setLoading(false);
          setProjectNameOptions(projectNameRes.data.data);
        }
        if (foremanRes.data.success) {
          setLoading(false);
          setForemanOptions(foremanRes.data.data);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleGeneralInfo = (e) => {
    const { name, value } = e.target;
    setGeneralData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { user } = useSelector((state) => state.user);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const arrays = [
        employeeHours,
        machineHours,
        materialHours,
        truckingHours,
      ]; //check if it has an error
      const errorFound = arrays.flat().some((item) => item.error === true);
      if (errorFound) {
        setLoading(false);
        return toast.error("please fill up correctly");
      }
      const data = {
        userId: user?.userId,
        projectName: generalData.projectName,
        foreman: generalData.foreman,
        date: generalData.date,
        notes,
        employee: user?.name,
        employeeHours,
        machineHours,
        materialHours,
        truckingHours,
      };
      const response = await postUserRecords({ data }); //call post UserRecords api
      if (response.data.success === true) {
        toast.success(response.data.message);
        setLoading(false);
        dispatch(
          updateAllcards({
            employee: "",
            employeeHours: [],
            machineHours: [],
            materialHours: [],
            truckingHours: [],
          })
        );
        navigate("/thankyou");
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("something went wrong");
    }
  };
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
  const day = String(currentDate.getDate()).padStart(2, "0"); // Ensure 2 digits
  const maxDate = `${year}-${month}-${day}`;

  return (
    <Layout>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h2 className="heading">General Review</h2>
              <p>
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
              </p>
            </div>
          </div>
          <div className="card-body">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="form-row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Project Name</label>
                    <select
                      className="js-example-placeholder-single selectdata js-states form-control"
                      value={generalData.projectName} // Use "value" instead of "defaultValue"
                      name="projectName"
                      onChange={handleGeneralInfo}
                      required
                    >
                      <option value="" disabled>
                        {loading ? "Loading..." : "Select Project Name"}
                      </option>
                      {projectNameOptions?.length > 0 ? (
                        projectNameOptions.map((project) => (
                          <option key={project._id} value={project.projectName}>
                            {project.projectName}
                          </option>
                        ))
                      ) : (
                        <option disabled>No data found</option>
                      )}
                    </select>
                    {!generalData.projectName && (
                      <span className="required-fields">Required</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>Foreman</label>
                    <select
                      className="js-example-placeholder-single js-states form-control"
                      value={generalData.foreman} // Use "value" instead of "defaultValue"
                      name="foreman"
                      onChange={handleGeneralInfo}
                    >
                      <option value="" disabled>
                        {loading ? "Loading..." : "Select Foreman"}
                      </option>
                      {foremanOptions?.length > 0 ? (
                        foremanOptions.map((foreman) => (
                          <option key={foreman._id} value={foreman.foreman}>
                            {foreman.foreman}
                          </option>
                        ))
                      ) : (
                        <option disabled>No data found</option>
                      )}
                    </select>
                    {!generalData.foreman && (
                      <span className="required-fields">Required</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={generalData.date}
                          name="date"
                          onChange={handleGeneralInfo}
                          required
                          max={maxDate} // Set the max attribute to the current date
                        />
                        {!generalData.date && (
                          <span className="required-fields">Required</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <EmployeeHoursCard
                  employeeTimeCardStatus={false}
                  generalreviewStatus={true}
                />
                <MachineHoursCard
                  employeeTimeCardStatus={false}
                  generalreviewStatus={true}
                />
                <MaterialHoursCard
                  employeeTimeCardStatus={false}
                  generalreviewStatus={true}
                />
                <TruckingHoursCard
                  employeeTimeCardStatus={false}
                  generalreviewStatus={true}
                />
              </div>
              {/* <div className="form-group">
                <h4 className="bold">Notes</h4>
                <textarea
                  name=""
                  id=""
                  rows="5"
                  className="form-control"
                  onChange={handleChangeNotes}
                  value={notes}
                ></textarea>
              </div> */}
              <div className="row mt-4 gap-2">
                <div className="col-lg-5">
                  <button
                    className="btn btn-secondary w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className="col-lg-5 ml-auto">
                  {loading ? (
                    <button className="btn btn-primary w-100" disabled>
                      Loading...
                    </button>
                  ) : (
                    <button className="btn btn-primary w-100">Submit</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default GeneralReview;
