import React, { useEffect } from "react";
import DashboardMenu from "./DashboardPages/DashboardMenu";
// import Layout from "../components/layout/Layout";
import { Outlet } from "react-router-dom";
import DashboardHeader from "./DashboardPages/DashboardHeader";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { adminVarifyToken } from "../apiServices/AdminDashboardApiServices";
import { toast } from "react-hot-toast";

function Dashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    const adminToken = Cookies.get("adminAuth");
    if (!adminToken || adminToken === undefined || adminToken === null) {
      navigate("/dashboard");
      toast.error("token not found");
    } else {
      (() => {
        adminVarifyToken(adminToken)
          .then((res) => {
            if (res.data.success) {
              return;
            } else {
              toast.error("token expired");
              navigate("/dashboard");
            }
          })
          .catch((error) => {
            if (error) {
              Cookies.remove("adminAuth");
              navigate("/dashboard");
              toast.error("Token Expired");
            }
          });
      })();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <section className="dashboard-layout">
        <DashboardMenu />
        <main className="main-content">
          <DashboardHeader />
          <section className="main-scroll-wrapper">
            <div className="inner">
              <Outlet />
            </div>
          </section>
        </main>
      </section>
    </>
  );
}

export default Dashboard;
