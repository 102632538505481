import React, { useEffect, useState } from "react";
import "../assets/css/style.css";
import Layout from "../components/layout/Layout";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/user/userSlice";
import { BsEye, BsEyeSlash } from "react-icons/bs"; // Import Bootstrap icons for show/hide

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Track password visibility

  const { loading, isError, message } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userCredentials = { email, password };
    if (!email || !password) {
      toast.error("Please enter email and password");
      return;
    }
    const result = await dispatch(loginUser(userCredentials));
    if (result?.payload?.success === true && result?.payload?.user) {
      navigate("/user-dashboard/home");
      toast.success(result.payload.message);
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <section className="section-padding">
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-xl-4 col-lg-6 align-self-center">
              <h2 className="heading">Login</h2>
              <p></p>
            </div>
            <div className="col-xl-5 col-lg-6 ml-auto">
              <div className="card">
                <div className="card-body">
                  <form action="" onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="input-group-text"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        >
                          {showPassword ? <BsEyeSlash /> : <BsEye />}
                        </span>
                      </div>
                    </div>

                    {loading ? (
                      <button className="btn btn-primary w-100" disabled>
                        Loading...
                      </button>
                    ) : (
                      <button className="btn btn-primary w-100">Login</button>
                    )}
                    <div className="d-flex justify-content-end mt-2 ">
                      <Link to="/forgetPassword" className="fw-bold text-body">
                        <span className="text-end mt-3 mb-0">
                          Forgot Password?
                        </span>
                      </Link>
                    </div>
                    <div className="d-flex justify-content-center text-muted mt-3 mb-0">
                      <Link to="/signup" className="fw-bold text-body">
                        <span className="text-center text-muted mt-3 mb-0">
                          Don’t have an account?{" "}
                        </span>
                        <u>SignUp</u>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Login;
