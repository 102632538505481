import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.js";
import Login from "./pages/Login";
import Review from "./pages/Timecard";
import Signup from "./pages/Signup";
import ThankYou from "./pages/ThankYou";
import GeneralReview from "./pages/GeneralReview";
import GeneralInformation from "./pages/GeneralInformation";
import EmployeeTimeCard from "./pages/EmployeeTimeCard";
import AddEmployee from "./pages/AddEmployee";
import AddProject from "./pages/AddProject";
import PageNotFound from "./pages/PageNotFound";
// import "./assets/css/style.css";
import "./assets/scss/global.scss";
import ForgetPassword from "./pages/ForgetPassword";
import ProtectedRoutes from "./components/routes/ProtectedRoutes";
import PublicRoutes from "./components/routes/PublicRoutes";
import Dashboard from "./Dashboard/Dashboard";
import Users from "./Dashboard/DashboardPages/Users";
import Reports from "./Dashboard/DashboardPages/Reports";
import Projects from "./Dashboard/DashboardPages/Projects";
import IndidualReport from "./Dashboard/DashboardPages/IndidualReport";
import Foreman from "./Dashboard/DashboardPages/catelog/Foreman";
import ProjectName from "./Dashboard/DashboardPages/catelog/ProjectName";
import PendingReports from "./Dashboard/DashboardPages/PendingReports";
import IndidualPendingReport from "./Dashboard/DashboardPages/indidualPendingReports";
import DahboardLogin from "./Dashboard/DashboardPages/dashboardLogin";
import AdminProtectedRoute from "./Dashboard/authRoutes";
import UserDashboard from "./userDashboard/userDashboard";
import AllReportes from "./userDashboard/pages/AllReportes";
import Profile from "./userDashboard/pages/profile";
import MachineAssets from "./Dashboard/DashboardPages/catelog/MachineAsset";
import MaterialSupplier from "./Dashboard/DashboardPages/catelog/MaterialSupplier";
import MaterialType from "./Dashboard/DashboardPages/catelog/MaterialType";
import Truck from "./Dashboard/DashboardPages/catelog/Truck";

function App() {
  return (
    <Routes>
      <Route path="/" element={<PublicRoutes />}>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
      </Route>

      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/timecard" element={<Review />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/generalreview" element={<GeneralReview />} />
        <Route path="/generalinformation" element={<GeneralInformation />} />
        <Route path="/employeetimecard" element={<EmployeeTimeCard />} />
        <Route path="/addemployee" element={<AddEmployee />} />
        <Route path="/addproject" element={<AddProject />} />
        <Route path="/user-dashboard" element={<UserDashboard />}>
          <Route path="home" element={<AllReportes />} />
          <Route path="profile" element={<Profile />} />
        </Route>
      </Route>

      {/* Admin dashboard routes */}
      <Route
        path="/dashboard/admin"
        element={
          <AdminProtectedRoute>
            <Dashboard />
          </AdminProtectedRoute>
        }
      >
        <Route index path="users" element={<Users />} />
        <Route path="reports" element={<Reports />} />
        <Route path="reports/:id" element={<IndidualReport />} />
        <Route path="projects" element={<Projects />} />
        <Route path="catalog-projects" element={<ProjectName />} />
        <Route path="catalog-foreman" element={<Foreman />} />
        <Route path="pending-reports" element={<PendingReports />} />
        <Route path="pending-reports/:id" element={<IndidualPendingReport />} />
        <Route path="machine-assets" element={<MachineAssets />} />
        <Route path="material-supplier" element={<MaterialSupplier />} />
        <Route path="material-type" element={<MaterialType />} />
        <Route path="truck" element={<Truck />} />
      </Route>

      <Route path="/dashboard" element={<DahboardLogin />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
