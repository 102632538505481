import React, { useEffect, useState } from "react";
import { fetchAllReports } from "../../apiServices/AdminDashboardApiServices";
import { useNavigate } from "react-router-dom";

function Reports() {
  const navigate = useNavigate();
  const [data, setData] = useState(null); // Set initial data state to null
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const result = await fetchAllReports();
        setData(result);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data.");
        setLoading(false);
      }
    };

    fetchDataAsync();
  }, []);

  if (loading) {
    return (
      <div className="card w-100 p-3 m-3">
        <h4 className="bold  p-2">Loading...</h4>
      </div>
    );
  }

  if (error || !data) {
    // Check for error or empty data
    return (
      <div>
        <div className="card w-100 p-3 m-3">
          <h4 className="bold  p-2">No data found</h4>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card p-3 m-3">
        <div style={{ fontWeight: "bold", fontSize: "20px" }} className="m-1">
          Reports ({data.userRecordsCount ? data.userRecordsCount : 0})
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {data.userRecords.map(
            (
              userRecords // Check for userRecords before mapping
            ) => (
              <div
                className="card mb-3 "
                style={{ maxWidth: "17rem" }}
                key={userRecords._id}
              >
                <div
                  className="card-header"
                  style={{
                    borderRadius: "29px 29px 0px 0px",
                    textTransform: "capitalize",
                  }}
                >
                  <span>
                    Employee:{" "}
                    {userRecords.employee &&
                      userRecords.employee.charAt(0).toUpperCase() +
                        userRecords.employee.slice(1)}
                  </span>
                  <br />
                  <span> Status: {userRecords.status}</span>
                </div>
                <div className="card-body">
                  <h5 className="card-title">
                    Project: {userRecords.projectName}
                  </h5>

                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      Employee Logs: {userRecords.employeeHours.length}
                    </li>
                    <li className="list-group-item">
                      Machine Logs: {userRecords.machineHours.length}
                    </li>
                    <li className="list-group-item">
                      Material Logs: {userRecords.materialHours.length}
                    </li>
                    <li className="list-group-item">
                      Trucking Logs: {userRecords.truckingHours.length}
                    </li>
                  </ul>
                </div>
                <button
                  type="button"
                  className="btn btn-primary m-4"
                  onClick={() => {
                    navigate(`${userRecords._id}`);
                  }}
                >
                  View
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default Reports;
