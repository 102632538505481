import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs"; // Import Bootstrap icons for show/hide

const ChangePassword = ({ id }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Track confirm password visibility
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      try {
        setLoading(true);
        const res = await axios.put(
          `${process.env.REACT_APP_API}/newpassword`,
          {
            id: id,
            password: newPassword,
          },
          {}
        );
        const { success, message } = res.data;
        if (success) {
          setNewPassword("");
          setConfirmPassword("");
          setLoading(false);
          navigate("/");
          toast.success(message);
        }
      } catch (err) {
        toast.error(err.response.data.message);
        setLoading(false);
      }
    } else {
      toast.error("Passwords do not match");
      setLoading(false);
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div
      className="row"
      style={{ justifyContent: "center", width: "80%", margin: "auto" }}
    >
      <div className="col-xl-8 col-lg-6 ml-auto">
        <div className="card">
          <div className="card-body">
            <form action="" onSubmit={handleSubmit}>
              <div className="form-group">
                <label>New Password</label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    value={newPassword}
                    autoComplete="off"
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                  <span
                    className="input-group-text"
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <BsEyeSlash /> : <BsEye />}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label>Re-type New Password</label>
                <div className="input-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  <span
                    className="input-group-text"
                    onClick={toggleConfirmPasswordVisibility}
                    style={{ cursor: "pointer" }}
                  >
                    {showConfirmPassword ? <BsEyeSlash /> : <BsEye />}
                  </span>
                </div>
              </div>
              {loading ? (
                <button className="btn btn-primary w-100" disabled>
                  Loading...
                </button>
              ) : (
                <button className="btn btn-primary w-100" type="submit">
                  Confirm
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
