import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import "../assets/css/style.css";
import { useNavigate } from "react-router-dom";
import EmployeeHoursCard from "./cards/EmployeeHoursCard";
import MachineHoursCard from "./cards/MachineHoursCard";
import MaterialHoursCard from "./cards/MaterialHoursCard";
import TruckingHoursCard from "./cards/TruckingHoursCard";
import { toast } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import {
  updateEmployeeName,
  updateAllcards,
} from "../redux/employeeTimecard/timeCardSlice";

const EmployeeTimeCard = () => {
  const [employeeName, setEmployeeName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    user && setEmployeeName(user?.name);
    user && dispatch(updateEmployeeName({ EmployeeName: user.name }));
  }, [dispatch, user]);

  const { employeeHours, machineHours, materialHours, truckingHours } =
    useSelector((state) => state.employeeTimecardData); // get data from redux store // get data from redux store
  const handleSubmit = (e) => {
    e.preventDefault();
    const arrays = [employeeHours, machineHours, materialHours, truckingHours]; //check if it has error
    const errorFound = arrays.flat().some((item) => item.error === true);
    console.log(arrays, "&&");
    if (errorFound) {
      return toast.error("please fill all fields correctly");
    }
    const isEmpty = arrays.every((array) => array.length === 0);

    if (isEmpty) {
      return toast.error("You have not logged any time");
    }
    navigate("/timecard");
  };
  // reset state if user canceled
  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(
      updateAllcards({
        employee: employeeName,
        employeeHours: [],
        machineHours: [],
        materialHours: [],
        truckingHours: [],
      })
    );
    navigate("/generalinformation");
  };

  return (
    <Layout>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h2 className="heading">Employee Timecard</h2>
              <p>
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <h4 className="bold">Select State</h4>
                <div className="form-lg-group">
                  <label>Employee</label>
                  <input
                    className={`js-example-placeholder-single js-states form-control`}
                    // onChange={(e) => handleEmployeeChange(e)}
                    value={employeeName}
                    required
                    disabled
                  />
                </div>
                <h4 className="bold">Employee Hours</h4>
                <EmployeeHoursCard
                  employeeTimeCardStatus={true}
                  generalreviewStatus={false}
                />
                <h4 className="bold">Machine Hours</h4>
                <MachineHoursCard
                  employeeTimeCardStatus={true}
                  generalreviewStatus={false}
                />
                <h4 className="bold">Materials</h4>
                <MaterialHoursCard
                  employeeTimeCardStatus={true}
                  generalreviewStatus={false}
                />
                <h4 className="bold">Trucking Hours</h4>
                <TruckingHoursCard
                  employeeTimeCardStatus={true}
                  generalreviewStatus={false}
                />
                <div className="row mt-4">
                  <div className="col-lg-5">
                    <button
                      className="btn btn-secondary w-100"
                      onClick={(e) => handleCancel(e)}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-lg-5 ml-auto">
                    <button type="submit" className="btn btn-primary w-100">
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default EmployeeTimeCard;
