// import React, { useEffect } from "react";
import plusImage from "../../assets/images/feather-plus-square.svg";
import "../../assets/css/style.css";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployeeHours } from "../../redux/employeeTimecard/timeCardSlice";
import { useEffect, useState } from "react";

const EmployeeHoursCard = ({ generalreviewStatus, employeeTimeCardStatus }) => {
  const [employeeName, setEmployeeName] = useState();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    setEmployeeName(user?.name);
  }, [user]);
  const { employeeHours } = useSelector((state) => state.employeeTimecardData);

  const handleClick = (e) => {
    e.preventDefault();
    const newCard = {
      id: employeeHours.length + 1,
      start: "",
      end: "",
      ol: "",
      totalTime: { hours: 0, minutes: 0 },
      error: false,
      employeeName: employeeName,
    };
    const updatedCards = [...employeeHours, newCard];
    dispatch(updateEmployeeHours({ cards: updatedCards }));
  };

  const removeCard = (e, cardId) => {
    e.preventDefault();
    const updatedCards = employeeHours
      .filter((card) => card.id !== cardId)
      .map((card, index) => {
        return {
          ...card,
          id: index + 1,
        };
      });

    dispatch(updateEmployeeHours({ cards: updatedCards }));
  };

  const calculateTotalTime = (start, end) => {
    const startTimestamp = new Date(`2000-01-01T${start}`).getTime();
    const endTimestamp = new Date(`2000-01-01T${end}`).getTime();
    const timeDifference = Math.abs(endTimestamp - startTimestamp);
    const totalMinutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const totalTime = {
      hours,
      minutes,
    };

    return totalTime;
  };

  const handleInputValues = (e, index, field) => {
    const { value } = e.target;
    const updatedCards = employeeHours.map((card, cardIndex) => {
      if (cardIndex === index) {
        return {
          ...card,
          [field]: value,
        };
      }
      return card;
    });

    const startValue = updatedCards[index].start;
    const endValue = updatedCards[index].end;

    if (startValue !== "" && endValue !== "") {
      const startTimestamp = new Date(`2000-01-01T${startValue}`).getTime();
      const endTimestamp = new Date(`2000-01-01T${endValue}`).getTime();

      if (endTimestamp <= startTimestamp) {
        updatedCards[index].error = true;
        updatedCards[index].totalTime = {
          hours: 0,
          minutes: 0,
        };
      } else {
        updatedCards[index].error = false;
        updatedCards[index].totalTime = calculateTotalTime(
          startValue,
          endValue
        );
      }
    } else {
      updatedCards[index].error = false;
      updatedCards[index].totalTime = {
        hours: 0,
        minutes: 0,
      };
    }
    dispatch(updateEmployeeHours({ cards: updatedCards }));
  };

  // useEffect(() => {
  //   // Fetch initial employee hours data here if needed
  // }, []);

  return (
    <div>
      <div style={{ display: `${employeeTimeCardStatus ? "inline" : "none"}` }}>
        {employeeHours.map((card, index) => (
          <div key={card.id}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <div className="row">
                <div className="col">
                  <label>Start</label>
                  <input
                    type="time"
                    className="form-control"
                    value={card.start}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "start");
                    }}
                  />
                  {card.start === "" && (
                    <span className="required-fields">Required</span>
                  )}
                </div>

                <div className="col">
                  <label>End</label>
                  <input
                    type="time"
                    className={`form-control ${card.error ? "is-invalid" : ""}`}
                    value={card.end}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "end");
                    }}
                  />
                  {card.error && (
                    <div className="invalid-feedback">
                      End time must be greater than start time.
                    </div>
                  )}
                  {card.end === "" && (
                    <span className="required-fields">Required</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>O/L</label>
                  <select
                    className="form-control"
                    value={card.ol}
                    required
                    onChange={(e) => {
                      handleInputValues(e, index, "ol");
                    }}
                  >
                    <option value="" disabled>
                      Choose...
                    </option>
                    <option value="O">O</option>
                    <option value="L">L</option>
                  </select>
                  {card.ol === "" && (
                    <span className="required-fields">Required</span>
                  )}
                </div>

                <div className="col">
                  <label>Total</label>

                  <input
                    type="text"
                    required
                    className="form-control"
                    value={`${card?.totalTime?.hours}h ${card?.totalTime?.minutes}m`}
                    onChange={(e) => handleInputValues(e, index, "totalTime")}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="removeBtn mt-2">
              <button onClick={(e) => removeCard(e, card.id)}>Remove</button>
            </div>
          </div>
        ))}

        {/* Button to add a new card */}
        <button
          className="btn btn-default w-100"
          id="machinebtn"
          onClick={handleClick}
        >
          <img src={plusImage} alt="btnImg" />
          Employee Hours
        </button>
      </div>

      {/* The below card is for generalReview component */}
      <div style={{ display: `${generalreviewStatus ? "inline" : "none"}` }}>
        <h4 className="bold">Employee Hours</h4>
        <div className="table-wrapper">
          <table className="table emp-table">
            <colgroup>
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
              <col width="20%" />
            </colgroup>
            <thead>
              <tr>
                <th>Name</th>
                <th>Start</th>
                <th>End</th>
                <th>Total</th>
                <th>O/L</th>
                <th className="print--hidden">Edit</th>
              </tr>
            </thead>
            <tbody>
              {employeeHours?.map((data, index) => (
                <tr key={data?.id}>
                  <td className="form-lg-group">
                    <input
                      className="js-example-placeholder-single js-states form-control"
                      value={data?.employeeName}
                      onChange={(e) =>
                        handleInputValues(e, index, "employeeName")
                      }
                      required
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="time"
                      className="form-control"
                      value={data?.start}
                      required
                      onChange={(e) => handleInputValues(e, index, "start")}
                    />
                    {data.start === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="time"
                      className={`form-control ${
                        data.error ? "is-invalid" : ""
                      }`}
                      value={data?.end}
                      onChange={(e) => handleInputValues(e, index, "end")}
                      required
                    />
                    {data.end === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={`${data?.totalTime?.hours}h ${data?.totalTime?.minutes}m`}
                      onChange={(e) => handleInputValues(e, index, "totalTime")}
                      disabled
                    />
                  </td>

                  <td>
                    <select
                      className="form-control"
                      // defaultValue={data?.ol}
                      value={data?.ol}
                      required
                      onChange={(e) => handleInputValues(e, index, "ol")}
                    >
                      <option value="" disabled>
                        choose
                      </option>
                      <option value="O">O</option>
                      <option value="L">L</option>
                    </select>
                    {data.ol === "" && (
                      <span className="required-fields">Required</span>
                    )}
                  </td>
                  <td className="print--hidden text-center">
                    <button
                      className="btn"
                      onClick={(e) => removeCard(e, data.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="addtr">
                <td colSpan="7">
                  <button
                    className="btn btn-default w-100"
                    id="addrow"
                    onClick={handleClick}
                  >
                    <img src={plusImage} alt="+" />
                    Employee Hours
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmployeeHoursCard;
